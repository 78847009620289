import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Link, List,
    ListItem, ListItemText,
    Modal,
    Stack
} from "@mui/material";
import {Layout} from "../../components/chrome/Layout";
import React from "react";
import {DataGrid, GridColDef, GridRenderCellParams, GridToolbar} from "@mui/x-data-grid";
import FlexGrid from "../../components/shared/flexGrid";
import {
    assessmentManagementApi, useGetAssessmentAnswerOptionsQuery,
    useGetAssessmentFormLanguagesQuery,
    useGetAssessmentUsageQuery
} from "../../rtkquery/apis/assessmentManagementApi";
import {UserServices} from "../../services/userService";
import {Error} from "../Error";
import {assessmentFormItem} from "../../rtkquery/types/assessmentFormItem";

export const FormItemListPage: React.FC = () => {

    const id = () => {
        var pathName = window.location.pathname;
        var pathArray = pathName.split('/');
        var id = pathArray[3];
        return parseInt(id);
    }

    const parentId = () => {
        var pathName = window.location.pathname;
        var pathArray = pathName.split('/');
        var id = pathArray[2];
        return parseInt(id);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        p: 4,
    };


    const userService = new UserServices();
    const [detailModalOpen, setDetailModalOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState<assessmentFormItem>();

    const {data: assessmentFormItems, isLoading} = assessmentManagementApi.useGetAssessmentFormItemsQuery(id());
    const {data: assessmentLanguageData} = useGetAssessmentFormLanguagesQuery(parentId());
    const {data: assessmentUsageData} = useGetAssessmentUsageQuery();
    const {data: assessmentAnswerOptionsData } = useGetAssessmentAnswerOptionsQuery(selectedRow?.answerGroupKey || 0, { skip: selectedRow === undefined});

    const selectedAssessmentUsage = assessmentUsageData?.find(assessment => assessment.instrumentKey === parentId());
    const selectedAssessmentLanguage = assessmentLanguageData?.find(assessment => assessment.formMasterKey === id());

    const columns: GridColDef[] = [
        {field: 'formItemKey', headerName: 'FormItemKey', width: 120, minWidth: 120},
        {field: 'formItemText', headerName: 'Name', flex: 1},
        {field: 'answerGroupName', headerName: 'Group Name', width: 200, minWidth: 200 },
        {field: 'formItemSeqNum', headerName: 'Seq Num', width: 200, minWidth: 200, type: 'number'},
        {
            field: "openBtn",

            headerName: " ",
            width: 100,
            renderCell: (params: GridRenderCellParams) => (
                <Button size={"small"} variant={"outlined"} onClick={() => openBtnClicked(params.row as assessmentFormItem)}>
                    Details
                </Button>
            )
        }
    ];

    const handleModalClose = () => setDetailModalOpen(false);

    const openBtnClicked = (row: assessmentFormItem) => {
        setSelectedRow(row);
        setDetailModalOpen(true);
    }


    function getRowId(row) {
        return row.formItemKey;
    }

    const parentPath = () => {
        return "/assessment-management/" + parentId().toString();
    }

    const path = () => {
        return "/assessment-management/" + parentId().toString() + "/" + id().toString();
    }

    if (!userService.isAssessmentManager()) {
        return (<Error/>);
    }

    return (
        <Layout>
            <Card>
                <CardContent>
                    <Stack>
                        <Box sx={{ paddingBottom: 5 }}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    href="/assessment-management"
                                >
                                    Assessment Management
                                </Link>
                                <Link underline="hover" color="inherit" href={parentPath()}>
                                    {selectedAssessmentUsage?.instrumentName}
                                </Link>
                                <Link
                                    underline="hover"
                                    color="text.primary"
                                    href={path()}
                                    aria-current="page"
                                >
                                    {selectedAssessmentLanguage?.formMasterName}
                                </Link>
                            </Breadcrumbs>
                        </Box>
                        <FlexGrid>
                            <div
                                style={{
                                    height: "800px",
                                    width: "100%",
                                    overflow: "auto",
                                }}
                            >
                                <DataGrid
                                    getRowId={getRowId}
                                    rows={assessmentFormItems}
                                    columns={columns}
                                    loading={isLoading}
                                    slots={{ toolbar: GridToolbar }}
                                />
                            </div>
                        </FlexGrid>
                    </Stack>
                </CardContent>
            </Card>

            <Modal
                open={detailModalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Card>
                        <CardHeader title={selectedRow?.formItemText}></CardHeader>
                        <CardContent>
                            <List>
                                {assessmentAnswerOptionsData?.map((item) => (
                                    <ListItem>
                                    <ListItemText
                                    primary={item.answerText}
                                    />
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Box>
            </Modal>
        </Layout>
    );
};
