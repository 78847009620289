import { TypographyOptions } from "@mui/material/styles/createTypography";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		botMessage: true;
		userMessage: true;
		postBackButton: true;
		submitButton: true;
		largest: true;
		title: true;
		caption2: true;
		question: true;
	}
}

interface ExtendedTypographyOptions extends TypographyOptions {
	botMessage: React.CSSProperties;
	userMessage: React.CSSProperties;
	postBackButton: React.CSSProperties;
	submitButton: React.CSSProperties;
	largest: React.CSSProperties;
	title: React.CSSProperties;
	caption2: React.CSSProperties;
	question: React.CSSProperties;
}

const typography: TypographyOptions = {
	fontSize: 14,
	fontWeightLight: 300,
	fontWeightRegular: 400,
	fontWeightMedium: 500,
	fontWeightBold: 600,
	h1: {
		fontSize: "2rem",
		fontWeight: 600,
		lineHeight: 1.25,
	},
	h2: {
		fontSize: "1.75rem",
		fontWeight: 600,
		lineHeight: 1.25,
	},
	h3: {
		fontSize: "1.5rem",
		fontWeight: 600,
		lineHeight: 1.25,
	},
	h4: {
		fontSize: "1.15rem",
		lineHeight: 1.25,
	},
	h5: {
		fontSize: "1.0625rem",
		fontWeight: 500,
		lineHeight: 1.25,
	},
	h6: {
		fontSize: "1rem",
		fontWeight: 500,
		lineHeight: 1.25,
	},
	body1: {
		fontSize: "0.95rem",
	},
	body2: {
		fontSize: "0.8rem",
		fontWeight: 600,
		alignSelf: "flex-start",
	},
	subtitle1: {
		fontSize: "1rem",
		fontWeight: "normal",
		alignSelf: "center",
	},
	subtitle2: {
		fontSize: "1.125rem",
		lineHeight: 1.25,
		fontWeight: 500,
	},
	button: {
		textTransform: "none",
	},
	botMessage: {
		color: "black",
		minHeight: 20,
		padding: "1rem",
		backgroundColor: "#f0f3f5",
		fontSize: 13,
		margin: 1,
		display: "inline-flex",
		borderRadius: "12px",
	},
	userMessage: {
		color: "white",
		minHeight: 50,
		minWidth: 100,
		backgroundColor: "rgba(0, 196, 180, 0.6)",
		padding: "1rem",
		margin: 1,
		alignSelf: "flex-end",
		display: "inline-flex",
		borderRadius: "12px",
	},
	postBackButton: {
		minHeight: 20,
		minWidth: 60,
		padding: "0.6rem",
		borderRadius: "1.2rem",
		marginTop: "0rem",
	},
	submitButton: {
		color: "#ffffff",
	},
	largest: {
		fontSize: "2rem",
		fontWeight: 900,
		lineHeight: 1.25,
	},
	title: {
		fontFamily: "NotoSans-Black",
		fontStyle: "normal",
		fontSize: "1.5rem",
		lineHeight: "133.4%",
		color: "#3B3B3B",
	},
	caption: {
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: "0.875rem",
		lineHeight: "1.25rem",
	},
	caption2: {
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: "0.813rem",
		lineHeight: "1.375rem",
	},
	question: {
		fontSize: "1rem",
		alignSelf: "center",
	},
} as ExtendedTypographyOptions;

export default typography;
