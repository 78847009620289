import { configureStore } from '@reduxjs/toolkit';
import userReducer from './User/UserSlice';
import navReducer from './Navigation/NavSlice';
import { assessmentManagementApi } from '../rtkquery/apis/assessmentManagementApi';

export const store = configureStore({
    reducer: {
        
        [assessmentManagementApi.reducerPath]: assessmentManagementApi.reducer,
        user: userReducer,
        navigation: navReducer,
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware().concat(assessmentManagementApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;