import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

import AppEmitter from "../../services/appEmitter";
import { RootState } from "../../store";
// import { Divider, Drawer, Grid, styled } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Divider, Drawer, Grid, styled } from "@mui/material";

import "./UserPanel.scss";

const envSettings = window as any;

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const UserPanel: React.FC = () => {
    const { userProfile } = useSelector((state: RootState) => state.user);
    const { user, isAuthenticated, logout, getIdTokenClaims } = useAuth0();
    const [showUserPanel, setShowUserPanel] = useState(false);

    const onLogout = () => {
        logout({
            returnTo: envSettings.REACT_APP_WEB_APP_URL,
        });
    };

    useEffect(() => {
        AppEmitter.on("SHOW_USER_PANEL", (value: Boolean) => {
            setShowUserPanel(value as any);
        });
        AppEmitter.on("APPLICATION_AXIOS_AUTH_ERROR", async (payload: any) => {
            console.log("on APPLICATION_AXIOS_AUTH_ERROR");
        });
        AppEmitter.on("APPLICATION_AXIOS_AUTH_ERROR_DONE", async (payload: any) => {
            console.log("on APPLICATION_AXIOS_AUTH_ERROR_DONE");
            onLogout();
        });
        // eslint-disable-next-line
    }, [user]);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" ||
                (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }

        setShowUserPanel(false);
    };

    return isAuthenticated ? (
        <Drawer
            anchor={"right"}
            open={showUserPanel}
            onClose={toggleDrawer(false)}
            sx={{ widht: 400 }}
        >
            <Box
                sx={{ width: 300 }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
            >
                <DrawerHeader>
                    <Grid container>
                        <Grid item xs={11} className="center">
                            <h3 className="font-weight-bold m-0">User Profile</h3>
                        </Grid>
                        <Grid item xs={1} className="right">
                            <Link to="#" className="close-button">
                                <CloseIcon onClick={() => setShowUserPanel(false)} />
                            </Link>
                        </Grid>
                    </Grid>
                </DrawerHeader>
                <Divider />
                <Grid container sx={{ m: 1, ml: 4, mb: 0 }}>
                    <Grid item xs={12}>
                        <h3>
                            {userProfile && userProfile.firstName ? (
                                <Fragment>
                                    {userProfile.firstName} {userProfile.lastName}
                                </Fragment>
                            ) : (
                                <Fragment>{user?.name}</Fragment>
                            )}
                        </h3>
                    </Grid>
                    <Grid item xs={12}>
                        <EmailIcon />
                        {user?.email}
                    </Grid>
                    <Grid item xs={12}>
                        <Link
                            data-testid="logout_button"
                            to="/logout"
                            className="btn btn-sm btn-primary font-weight-bolder py-2 px-5"
                        >
                            Sign Out
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Drawer>
    ) : (
        <Fragment></Fragment>
    );
};

export default UserPanel;
