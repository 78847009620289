import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Grid, Typography, TextField } from "@mui/material";

import { RootState } from "../../../../store";
import { QuestionProps } from "../DisplayQuestion";

export const MultiEditsComponent: React.FC<QuestionProps> = ({
	question,
	handleResponse,
}) => {
	const { activeAnswers, selectedLanguage, instrumentId } = useSelector((state: RootState) => state.user);

	const [maxStringLength, setMaxStringLength] = useState(0);
	const [options, setOptions] = useState(question?.options);

	useEffect(() => {
		if (question) {
			if (instrumentId === 1860 && question.options?.length) {
				setMaxStringLength(question.options[0].maxLength ?? 250);
			}
			else {
				setMaxStringLength(question.maxLength ?? 250);
			}
		}
	}, [question, activeAnswers]);

	useEffect(() => {}, [options]);

	const updateAnswer = (val: string, index) => {
		if (options?.length) {
			let answers = [...options];
			let arr = answers;
			arr = arr.map((a) => {
				if (a.formItemId === index) {
					return {
						...a,
						answer: val.substring(0, maxStringLength),
					};
				} else return a;
			});

			setOptions(arr);
		}
	};

	return (
		<Fragment>
			{options?.map((group, gindex) => {
				return (
					<Grid container key={gindex}>
						<Grid item xs={0.4} sx={{ mt: 6 }}>
							<Typography>{group.text}</Typography>
						</Grid>
						<Grid item xs={11.6} className="answer-input">
							<TextField
								id={`text_field_${group.formItemId}`}
								fullWidth
								key={gindex}
								size={"small"}
								multiline={true}
								rows={3}
								defaultValue={group.answer}
								value={group.answer}
								className="text-input"
								label={(group.placeholder).trim()}
								onBlur={(e) => {
									handleResponse &&
										handleResponse(
											group?.formItemId!,
											group?.formItemSequenceNumber!,
											e.target.value,
											false
										);
								}}
								sx={{ 
									'& label': {
										transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
										left: 'inherit !important',
										right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
									},
									'& legend': {
										textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
									}
								}}
								onChange={(e) => updateAnswer(e.target.value, group.formItemId)}
								error={
									group.answer?.length && group.answer.length > (Number(group.maxLength?.toString()) ?? maxStringLength)
								}
							/>
						</Grid>
						<Grid item xs={12} style={{ textAlign: "right" }} mt={-4}>
							<Typography variant="caption">
								{group.answer?.length ?? 0}/{group.maxLength?.toString() ?? maxStringLength}
							</Typography>
						</Grid>
					</Grid>
				);
			})}
		</Fragment>
	);
};
