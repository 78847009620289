import { useEffect, useState } from "react";
import {
	Box,
	Card,
	CardContent,
	Grid,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import { FormattedPhrase } from "../../context/FormattedPhrase";
import { Loading } from "../../components";
import { SurveyStatus } from "../../@types/raters.d";
import { DisplayInstramentLink } from "./DisplayProgramInstrumentStatus";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import moment from "moment";
import TabSwitch from "../../components/shared/tabSwitch";
import { useTranslationContext } from "../../context/TranslationContext";
import { VideoModal } from "../../components/shared/videoModal";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import "../HomePage.scss";

type CurrentSurveyProps = {
	programRaterStatuses: any;
};

export const CurrentSurveys: React.FC<CurrentSurveyProps> = ({
	programRaterStatuses
}) => {
	const [videoPlayerOpen, setVideoPlayerOpen] = useState("");
	const { getFormattedPhrase } = useTranslationContext();
	const [busy, setBusy] = useState(false);
	const [tabs] = useState([getFormattedPhrase("raterLanding.sorterLabel.name"), getFormattedPhrase("raterLanding.sorterLabel.assessmentName"), getFormattedPhrase("raterLanding.sorterLabel.dueDate")]);
	const [sortBy, setSortBy] = useState(tabs[2]);
	const { dateFormat, videos } = useSelector((state: RootState) => state.user);
	const theme = useTheme();

	const sortedPrograms = (data: any, sortBy: string) => {
		const sortData = data.slice();
		switch(tabs.findIndex((x) => x===sortBy)) {
			case 0: return sortData.sort((d1, d2) => d2.participant.firstName < d1.participant.firstName ? 1:-1);	
			case 1: return sortData.sort((d1, d2) => d2.instrument.name < d1.instrument.name ? 1:-1);	
			case 2: return sortData.sort((d1, d2) => d2.assessmentDueDate < d1.assessmentDueDate ? 1:-1);	
		}
	}

	useEffect(() => {},[sortBy])

	return (
		<>
			{busy && <Loading />}
			{programRaterStatuses?.length > 0 && (
				<Grid sx={{ borderRadius: "0.75rem"}}>
					<VideoModal 
						isOpen={videoPlayerOpen !== ""} 
						onClose={() => setVideoPlayerOpen("")} 
						videoList={[videos.rater.video]} 
						thumbnailList={[videos.rater.thumb]}

						name={"Rater Assessment"}
						assessmentName={videoPlayerOpen + " (Rater)"}
						playedFrom={"Assessments for Others"}

					/>
					<Grid container display={"flex"} alignContent={"center"}>
						<Grid item xs={12} md={6} pb={6} flex={1}>
							<Typography variant="title" pt={1}>
								<FormattedPhrase id="dashboard.header.currentAssessments" />
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<TabSwitch clickedTab={(tabName) => setSortBy(tabName)} tabs={tabs} activeTab={sortBy}/>
						</Grid>
					</Grid>
					{sortedPrograms(programRaterStatuses, sortBy).map(
						(status: SurveyStatus, index: number) => (
							<Card key={index} sx={{ mb: 3 }}>
							<CardContent>
								<Stack direction="row">
									<Stack sx={{ flex: 1 }}>
										<Typography variant="title" pl={3}>
											{status.participant.firstName}{" "}
											{status.participant.lastName}
										</Typography>
										<Typography
											variant="subtitle1"
											mt={1}
											pl={3}
											sx={{
												alignSelf: "flex-start",
												textTransform: "capitalize",
												color: "#76777B",
											}}
										>
											{getFormattedPhrase("raterLanding.card.raterType").replace("{{raterType}}",status.rater.raterType.name)}
										</Typography>
										<Box
											sx={{
												my: 5,
												mx: 3,
												p: 3,
												backgroundColor:
													status.surveyStatusId === 3 ||
													status.surveyStatusId === 4
														? theme.palette.primary.light
														: theme.palette.secondary.dark,
												borderRadius: "12px",
											}}
										>
											<Grid container className="survey-card">
												<Grid item xs={12}>
													<Typography variant="h5">
														{status.instrument.name}
														<IconButton sx={{pl: 3, color: "#307E92"}} onClick={() => setVideoPlayerOpen(status.instrument.name)}>
															<VideoCameraBackIcon />
														</IconButton>
													</Typography>
												</Grid>
												<Stack direction="row" mt={0}>
													<Stack direction="row" sx={{ mr: 5 }}>
														<CalendarMonthRoundedIcon
															sx={{
																mr: 2,
																width: "15px",
																height: "16px",
																color: "#000000",
															}}
														/>
														<Typography
															variant="body2"
															sx={{
																alignSelf: "flex-start",
																textTransform: "capitalize",
																color: "#000000",
															}}
															fontWeight={400}
														>
															<FormattedPhrase id="raterLanding.card.header.assessmentDueDate" />
															{': '}
														</Typography>
														<Typography variant="body2">
															{moment(status.assessmentDueDate).format(dateFormat)}
														</Typography>
													</Stack>
													<Stack direction="row" sx={{ mr: 3 }}>
														<AccessTimeFilledIcon
															sx={{
																mr: 2,
																width: "15px",
																height: "16px",
																color: "#000000",
															}}
														/>
														<Typography
															variant="body2"
															sx={{
																alignSelf: "flex-start",
																textTransform: "capitalize",
																color: "#000000",
															}}
															fontWeight={400}
														>
															<FormattedPhrase
																id="raterLanding.card.estimatedTimeToComplete"
																values={{
																	estimatedTimeToComplete:
																		status.estimatedTimeToComplete,
																}}
															/>
														</Typography>
													</Stack>
												</Stack>
											</Grid>
											<Grid item xs={12} sx={{ p: 3, pt: 0 }}>
												<Typography variant="body1" sx={{ fontWeight: 400 }}>
													{getFormattedPhrase("raterLanding.card.instructions").replace("{{participantFirstName}}", status.participant.firstName)}
												</Typography>
											</Grid>
											<Grid item xs={12} className="survey-card" pl={4}>
												<Box
													className="survey-warnings"
													sx={{ backgroundColor: theme.palette.secondary.contrastText }}
												>
													<Stack direction={"row"}>
														<DisplayInstramentLink
															participantId={status.participant.participantId}
															instrumentId={status.instrument.instrumentId}
															statusId={status.surveyStatusId as number}
															surveyTypeId={1}
															category={"assessment"}
															surveyPlatform={status.instrument.surveyPlatform}
														/>
													</Stack>
												</Box>
                                            </Grid>
										</Box>
									</Stack>
								</Stack>
							</CardContent>
						</Card>
						)
					)}
				</Grid>
			)}
		</>
	);
};
