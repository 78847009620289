import { Fragment, useEffect } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { InstrumentStatus } from "../../@types/surveys.d";
import { DisplayInstramentLink } from "./DisplayProgramInstrumentStatus";
import aboutMeImage from "../../assets/images/about_me.png";
import { FormattedPhrase } from "../../context/FormattedPhrase";

import "../HomePage.scss";

type ComponentProps = {
	instrumentStatus: InstrumentStatus;
};

export const DisplayAboutMeStatus: React.FC<ComponentProps> = ({
	instrumentStatus,
}) => {
	useEffect(() => {}, [instrumentStatus]);

	return (
		<Fragment>
			<Card
				sx={{
					px: "17px",
					py: "5px",
					mb: 8,
					boxShadow:
						"0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
				}}
			>
				<CardContent>
					<Grid container spacing={0} rowSpacing={0}>
						<Grid item xs={12}>
							<img
								src={aboutMeImage}
								alt="About Me"
								style={{ width: "100%" }}
							/>
						</Grid>
						<Grid item xs={12} sx={{ mt: 5 }}>
							<Typography variant="title">
								<FormattedPhrase id="participant.aboutMe.assessmentName" />
							</Typography>
							<Typography variant="body1" mt={3}>
								<FormattedPhrase id="surveys.aboutMe.description" />
							</Typography>
						</Grid>
						<Grid item sx={{ mt: 5, marginLeft: "auto" }}>
							<DisplayInstramentLink
								instrumentId={instrumentStatus.instrumentId}
								participantId={instrumentStatus.participantId}
								instrumentStatus={instrumentStatus}
								statusId={instrumentStatus?.selfStatus?.statusId as number}
								category={"About Me"}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Fragment>
	);
};
