import { Fragment, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { Button, Grid, useTheme } from "@mui/material";

import { RootState } from "../../store";
import {
	setCurrentSection,
	setLocalSurveyLanguage,
	setSelectedSurveyLanguage,
	setSurveyReopened,
} from "../../store/User/UserSlice";

import Loading from "../chrome/Loading";
import { SurveySectionComponent } from "./sections/SurveySectionComponent";
import { SurveyCompleteComponent } from "./sections/SurveyCompleteComponent";
import { SurveyNavigation } from "./SurveyNavigation";
import { FormattedPhrase } from "../../context/FormattedPhrase";
import { Language } from "../../@types/language.d";

type SurveyProps = {
	centerQuestions: boolean;
};

export const Survey: React.FC<SurveyProps> = ({ centerQuestions }) => {
	const dispatch = useDispatch();
	const { activeSurvey, currentSection, loading, surveyReopened, selectedSurveyLanguage, localSurveyLanguage, selectedLanguage } = useSelector(
		(state: RootState) => state.user
	);

	const { reviewUnanswered } = useSelector(
		(state: RootState) => state.navigation
	);

	const [completeText, setCompleteText] = useState<string>("");
	const [reviewText, setReviewText] = useState<string>("");
	const [isValid, setIsValid] = useState<boolean>();
	const theme = useTheme();
	const history = useHistory();

	useEffect(() => {
		if (activeSurvey?.surveyDocument?.survey?.surveySections?.length > 0) {
			let text =
				activeSurvey?.surveyDocument?.survey?.surveySections[0].extensions?.find(
					(x) => x.name === "updateComplete"
				);
			if (text) {
				setCompleteText(text.value);
			}

			text =
				activeSurvey?.surveyDocument?.survey?.surveySections[0].extensions?.find(
					(x) => x.name === "reviewResponses"
				);
			if (text) {
				setReviewText(text.value);
			}

			// Check if valid
			checkIfValid();
			getSelectedSurveyLanguage();
		}
		// scroll to top only if there is no next unanswered question or not navigating to a specific question
		if (
			JSON.stringify(reviewUnanswered) === "{}" ||
			reviewUnanswered.formItemId === 0
		)
			window.scrollTo(0, 0);
	}, [activeSurvey, currentSection]);

	useEffect(() => {}, [selectedSurveyLanguage, localSurveyLanguage, selectedLanguage])

	useEffect(() => {
		let redirectUrl = localStorage.getItem("returnToAp1");
		let redirectUser = localStorage.getItem("redirectUser");

		if (redirectUser === "true" && redirectUrl) {
			// redirect user only if they have come from AP1
			localStorage.setItem("redirectUser", "");
			localStorage.setItem("returnToAp1", "");
			window.location.href = redirectUrl;
		}
	},[]);

	useEffect(() => {
		const handleRefresh = (event: Event) => {
			localStorage.setItem("redirectUser", "");
			let returnUrl = localStorage.getItem("returnToAp1");
			if (returnUrl !== null) {
				history.replace(returnUrl);
				localStorage.setItem("redirectUser", "true");
				return;
			}
		};

		window.addEventListener('beforeunload', handleRefresh);

		return () => {
			window.removeEventListener('beforeunload', handleRefresh);
		};
	}, []);

	const getSelectedSurveyLanguage = () => {
		let languages = activeSurvey?.surveyDocument?.languages;
		let languageId = activeSurvey?.surveyDocument?.survey?.languageId;
		languages.map(x => {
			if (x.languageId === languageId) {
				dispatch(setLocalSurveyLanguage(x.cultureName));
				let surveyLanguage = activeSurvey?.surveyDocument?.languages?.find(
					(l) => l.languageId === languageId
				);
				dispatch(setSelectedSurveyLanguage(surveyLanguage as Language));
			}
		});
	}

	const onReview = (e: any) => {
		dispatch(setSurveyReopened(true));

		let newSection = activeSurvey.surveyDocument.survey.surveySections[0];
		dispatch(setCurrentSection(newSection));
	};

	const checkIfValid = async () => {
		for (
			let s = 0;
			s < activeSurvey.surveyDocument.survey.surveySections.length;
			++s
		) {
			for (
				let g = 0;
				g <
				activeSurvey.surveyDocument.survey.surveySections[s].questionGroups
					?.length;
				++g
			) {
				let group =
					activeSurvey.surveyDocument.survey.surveySections[s].questionGroups[
						g
					];
				for (let q = 0; q < group.questions?.length; ++q) {
					let question = group.questions[q];
					if (!question.optional && question.answer === "") {
						// Not valid
						setIsValid(false);
						return;
					}
				}
			}
		}

		setIsValid(true);
	};

	const renderSurvey = () => {
		return (
			<Fragment>
				{activeSurvey && currentSection && (
					<div aria-describedby={`${currentSection.component}`}>
						{currentSection ? (
							<SurveySectionComponent
								surveySection={currentSection}
								centerQuestions={centerQuestions}
							/>
						) : (
							<Loading />
						)}
					</div>
				)}
			</Fragment>
		);
	};

	const renderSubmitted = () => {
		var completeSection =
			activeSurvey?.surveyDocument?.survey?.surveySections?.find(
				(s) => s.component === "SurveyCompleteComponent"
			);

		if (completeSection) {
			return (
				<SurveyCompleteComponent
					surveySection={completeSection}
					centerQuestions={false}
				/>
			);
		} else {
			return (
				<Fragment>
					<Row>
						<Col className="m-3">
							<h1>{activeSurvey.surveyDocument?.survey?.name}</h1>
						</Col>
					</Row>
					<Row>
						<Col className="m-3">
							<p>
								Your {activeSurvey.surveyDocument?.survey?.name} survey is
								complete.
							</p>
							<p>
								Please <Link to="/">click here</Link> to return to your
								assessment.
							</p>
						</Col>
					</Row>
				</Fragment>
			);
		}
	};

	const renderReview = () => {
		return (
			<Fragment>
				<Grid item xs={12} sm={12} md={12} className="rounded-card">
					<Row>
						<Col className="m-3">
							<h1>{activeSurvey.surveyDocument?.survey?.name}</h1>
						</Col>
					</Row>
					<Row>
						<Col className="m-3">{completeText}</Col>
					</Row>
					<Row style={{ marginTop: "2.5%" }}>
						<Col className="m-3">
							<Button
								sx={{
									backgroundColor: theme.palette.secondary.contrastText,
									borderColor: theme.palette.info.dark,
									color: theme.palette.primary.main,
									borderRadius: 100,
								}}
								variant="outlined"
								onClick={(e) => onReview(e)}
							>
								{reviewText}
							</Button>
							<Button
								sx={{
									backgroundColor: theme.palette.secondary.contrastText,
									borderColor: theme.palette.info.dark,
									color: theme.palette.primary.dark,
									borderRadius: 100,
									marginLeft: "1%",
								}}
								variant="outlined"
							>
								<Link to="/" style={{ textDecoration: "none" }}>
									<FormattedPhrase id="dashboard.footer.returnToAP" language={localSurveyLanguage} />
								</Link>
							</Button>
						</Col>
					</Row>
				</Grid>
			</Fragment>
		);
	};

	const renderByStatus = () => {
		if (activeSurvey?.surveyDocument) {
			switch (activeSurvey?.surveyDocument.surveyStatusId) {
				default:
				case 1:
				case 2:
					return <Fragment>{renderSurvey()}</Fragment>;

				case 3:
					return <Fragment>{renderSubmitted()}</Fragment>;

				case 4:
					if (surveyReopened) {
						return <Fragment>{renderSurvey()}</Fragment>;
					}
					return <Fragment>{renderReview()}</Fragment>;
			}
		}

		return <Fragment />;
	};

	return (
		<Fragment>
			{loading && <Loading />}
			{renderByStatus()}
			<SurveyNavigation />
		</Fragment>
	);
};
