import { useEffect } from 'react';

const usePageTitle = (title: string): void => {
  useEffect(() => {
    document.title = title;

    return () => {
      document.title = 'Assessments | Center for Creative Leadership';
    };
  }, [title]);
};

export default usePageTitle;