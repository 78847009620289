import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	Checkbox,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from "@mui/material";

import { QuestionProps } from "../DisplayQuestion";
import { RootState } from "../../../../store";
import { getSelectedAnswerArray } from "../../store/SurveyState";

import "../../sections/SurveySection.scss";

export const RadioComponent: React.FC<QuestionProps> = ({
	question,
	handleResponse,
}) => {
	const { activeAnswers } = useSelector((state: RootState) => state.user);

	const [answerArray, setAnswerArray] = useState<Array<string>>([]);

	useEffect(() => {
		if (question) {
			let a =
				getSelectedAnswerArray &&
				getSelectedAnswerArray(question.formItemId, activeAnswers);
			setAnswerArray(a ?? []);
		}
	}, [question, activeAnswers]);

	return (
		<FormControl>
			{question.options?.map((option, optindex) => {
				return (
					<FormControlLabel
						key={optindex}
						value={option.value}
						control={
							<Radio
								color={"secondary"}
								name={`radio-group-${question.formItemId}`}
								checked={answerArray && answerArray.indexOf(option.value) >= 0}
								onChange={(e) =>
									handleResponse &&
									handleResponse(
										question.formItemId,
										question.formItemSequenceNumber,
										e.target.value,
										false
									)
								}
							/>
						}
						label={option.text}
					/>
				);
			})}
			{question.optionGroups ? (
				question.optionGroups?.map((group, index) => {
					return (
						<RadioGroup
							key={index}
							aria-labelledby="demo-radio-buttons-group-label"
							defaultValue="female"
							name="radio-buttons-group"
						>
							{group.options?.map((option, optindex) => {
								return (
									<FormControlLabel
										value={option.value}
										control={
											<Radio
												color={"secondary"}
												name={`radio-group-${question.formItemId}`}
												checked={
													answerArray && answerArray.indexOf(option.value) >= 0
												}
												onChange={(e) =>
													handleResponse &&
													handleResponse(
														question.formItemId,
														question.formItemSequenceNumber,
														e.target.value,
														false
													)
												}
											/>
										}
										label={option.answer}
									/>
								);
							})}
						</RadioGroup>
					);
				})
			) : (//Consent Question (I Understand)
				<>
					{question.options == null && (
						<FormControlLabel
							className={question.component === null ? "check-box" : ""}
							key={question.formItemId}
							control={
								<Checkbox
									color={"secondary"}
									name={`checkbox-group-${question.formItemId}`}
									checked={answerArray.indexOf("1") >= 0}
									onChange={(e) =>
										handleResponse &&
										handleResponse(
											question.formItemId,
											question.formItemSequenceNumber,
											e.target.value, //for scoring purposes 
											true
										)
									}
								/>
							}
							value={"1"} //for scoring purposes 
							label={question.text}
						/>
					)}
				</>
			)}
		</FormControl>
	);
};
