import { useState } from "react";

import { TranslationContext } from "./TranslationContext";

import ar_SA from "../assets/i18n/ar-SA.json";
import en_US from "../assets/i18n/en-US.json";
import en_GB from "../assets/i18n/en-GB.json";
import de_DE from "../assets/i18n/de-DE.json";
import es_ES from "../assets/i18n/es-ES.json";
import es_MX from "../assets/i18n/es-MX.json";
import fr_FR from "../assets/i18n/fr-FR.json";
import it_IT from "../assets/i18n/it-IT.json";
import nl_NL from "../assets/i18n/nl-NL.json";
import pl_PL from "../assets/i18n/pl-PL.json";
import pt_BR from "../assets/i18n/pt-BR.json";
import pt_PT from "../assets/i18n/pt-PT.json";
import ru_RU from "../assets/i18n/ru-RU.json";
import zh_CN from "../assets/i18n/zh-CN.json";

type TranslationProviderProps = {
	children: React.ReactNode;
};

export const TranslationProvider: React.FC<TranslationProviderProps> = ({
	children,
}) => {
	const allLanguageContent = {
		"ar-SA": ar_SA,
		"en-US": en_US,
		"en-GB": en_GB,
		"de-DE": de_DE,
		"es-ES": es_ES,
		"es-MX": es_MX,
		"fr-FR": fr_FR,
		"it-IT": it_IT,
		"nl-NL": nl_NL,
		"pl-PL": pl_PL,
		"pt-BR": pt_BR,
		"pt-PT": pt_PT,
		"ru-RU": ru_RU,
		"zh-CN": zh_CN,
	};

	const [chosenLanguage, _setChosenLanguage] = useState<string>("en-US");

	const setChosenLanguage = (language: string) => {
		_setChosenLanguage(language);
	};

	const getFormattedPhrase = (c: string, v: any, l?: string) => {
		try {
			let formattedPhrase = "";
			if (l) {
				formattedPhrase = allLanguageContent[l][c];
			} else if (chosenLanguage) {
				formattedPhrase = allLanguageContent[chosenLanguage][c];
			} else {
				formattedPhrase = allLanguageContent["en-US"][c];
			}
			return formattedPhrase;
		} catch (error) {
			console.log("error", error);
			return c;
		}
	};

	return (
		<TranslationContext.Provider
			value={{ chosenLanguage, setChosenLanguage, getFormattedPhrase }}
		>
			{children}
		</TranslationContext.Provider>
	);
};
