export const firebaseConfig_dev = {
    apiKey: "AIzaSyD1ZpeoNEmFR5C8vZ6gjlThvBT1siVIHok",
    authDomain: "assessment-portal-dev-90817.firebaseapp.com",
    projectId: "assessment-portal-dev-90817",
    storageBucket: "assessment-portal-dev-90817.appspot.com",
    messagingSenderId: "6579169511",
    appId: "1:6579169511:web:261e13cebadebe215c5a42",
    measurementId: "G-9BSKJHR809"
};

export const firebaseConfig_qa = {
    apiKey: "AIzaSyBVrj52HL1pPCQXA7rntIx8hX_xgDQALCQ",
    authDomain: "assessment-portal-qa.firebaseapp.com",
    projectId: "assessment-portal-qa",
    storageBucket: "assessment-portal-qa.appspot.com",
    messagingSenderId: "732062571430",
    appId: "1:732062571430:web:68f9022b5f927d34fa4d67",
    measurementId: "G-Z32CF8BEEX"
};

export const firebaseConfig_uat = {
    apiKey: "AIzaSyBCrg3quif8PdMJSigGoP_r_CL0ceFW_k8",
    authDomain: "assessment-portal-uat.firebaseapp.com",
    projectId: "assessment-portal-uat",
    storageBucket: "assessment-portal-uat.appspot.com",
    messagingSenderId: "582754596401",
    appId: "1:582754596401:web:db890944e9997060a0c710",
    measurementId: "G-SMZCB2LS7C"
  };

export const firebaseConfig_prod = {
	apiKey: "AIzaSyDITTQONqF3pNxGbJDjFoSR_rl2vre_hMA",
	authDomain: "about-me-dcb01.firebaseapp.com",
	projectId: "about-me-dcb01",
	storageBucket: "about-me-dcb01.appspot.com",
	messagingSenderId: "296723557075",
	appId: "1:296723557075:web:c0a0017013d06191298226",
	measurementId: "G-VS9DYP95LZ",
};