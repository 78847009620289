import { Rule } from "json-rules-engine";
import { Language } from "./language.d";

export interface SurveyResponse {
	surveyDocument: SurveyDocument;
	surveyAnswers: Array<SurveyAnswer>;
}

export interface SurveyAnswer {
	surveyDocumentId: string;
	formItemId: number;
	formItemSequenceNumber: number;
	answer: string;
	optional: boolean;
	answeredTimeMs: number;
}

export interface SurveyDocument {
	id: string;
	partitionId: string;
	participantId: number;
	individualId: number;
	surveyStatusId: number;
	languages: Array<Language>;
	survey: Survey;
}

export interface Survey {
	id: string;
	schemaVersion: null;
	instrumentId: number;
	surveyTypeId: number;
	languageId: number;
	formMasterId: number;
	name: string;
	nameTreatment: null;
	lastAnsweredFormItemId: null;
	extensions: null;
	surveySections: Array<SurveySection>;
}

export interface SurveySection {
	sectionId: string;
	component: string;
	heading: string;
	jumpMenu: string;
	instructions: Array<string>;
	nextButtonText: string;
	previousButtonText: string;
	exitButtonText: null;
	nextGroupButtonText: null;
	previousGroupButtonText: null;
	optional: boolean;
	reviewable: boolean;
	extensions: Array<Extension>;
	questionGroups: Array<QuestionGroup>;
	validationRule: null;
	surveyScale: null;
	subheadings: Array<string>;
	information: Array<string>;
	additionalInstructions: null;
	submitButtonText: string;
	warnings: Array<string>;
	allowedLanguages: null;
	conditionalValidators?: string;
}

export interface Extension {
	name: string;
	value: string;
}

export interface QuestionGroup {
	questions: Array<Question>;
	instructions: null;
	heading: GroupHeading;
	tabText?: string;
	surveyScale: SurveyScale;
	answerGroup: Array<AnswerGroup>;
}

export interface GroupHeading {
	formItemSequenceNumber: number;
	text: string;
}

export interface AnswerGroup {
	text: string;
	value: string;
}

export interface SurveyScale {
	buttons: Array<Button>;
	notApplicableButton: Button;
}

export interface Button {
	buttonText: string;
	buttonValue: string;
	meaning: string;
	reviewable: boolean;
}

export interface Question {
	component: string;
	formItemSequenceNumber: number;
	formItemId: number;
	parentFormItemId: number;
	number: string;
	text: string;
	subtext?: string;
	options?: Array<Option>;
	optionGroups?: Array<OptionGroup>;
	optional: boolean;
	maxLength?: number;
	multiline?: boolean;
	hasOptions?: boolean;
	answer: string;
	placeholder: string;
	extensions: Array<Extension>;
	validators: Array<ResponseValidator>;
	dependentOption: DependentOption;
	conditionalValidators: Array<ConditionalValidator>;
}

export interface ResponseValidator {
	name: string;
	message: string;
	parameters: Array<any>;
}

export interface ConditionalValidator {
	formItemSequenceNumber: number;
	formItemId: number;
	answers: Array<string>;
	optional: boolean;
	visible: boolean;
}

export interface NavigationEvent {
	source: string;
	sectionIndex: number;
}

export enum Component {
	Radio = "Radio",
	SingleSelect = "SingleSelect",
	Text = "Text",
}

export interface OptionGroup {
	name: Name;
	options: Array<Option>;
}

export enum Name {
	Todo = "TODO",
}

export interface Option {
	text: string;
	subtext: null;
	value: string;
	formItemId: null;
	formItemSequenceNumber?: number;
	dependentFormItemSequenceNumber?: number;
	number: string;
	answer?: any;
	maxLength?: number;
	multiline?: boolean;
	placeholder: string;
	dependentOption: DependentOption;
}

export interface DependentOption {
	formItemId?: number;
	formItemSequenceNumber?: number;
	value: string;
	answer?: any;
	maxLength?: number;
	multiline?: boolean;
	placeholder: string;
	optional?: boolean;
}

export interface DocumentInfo {
	documentId: number;
	name: string;
}

export interface AssetInfo {
	assetInfoId: number;
	contentType: string;
	name: string;
	latestVersion: string;
}

export interface InstrumentStatus {
	assessmentDueDate: string;
	assessmentItemTypeId: number;
	assessmentItemTypeName?: any;
	assessmentItemName: string;
	assessmentLinkInfo: AssessmentLinkInfo;
	assessmentLink: string;
	assetAvailable: boolean;
	assetInfo: AssetInfo;

	documentId?: any;
	documentInfo: DocumentInfo;
	documentName?: any;
	documentPath?: any;

	formMasterId: number;

	instrumentTypeId: number;
	instrumentTypeName: string;
	instrumentId: number;
	isAssessment: boolean;
	is360: boolean;

	localizedLongDescription: string;

	needMoreRaters: boolean;

	participantId: number;
	programCode?: any;
	programId: number;
	programName: string;
	programStartDate: string;

	raterInvitedCount: number;
	raterSurveySubmittedCount: number;
	raterSurveyPercentCompleted: number;
	reportLink: string;

	selfStatusId: number;
	selfStatus: Status;
	selfStatusName: string;
	surveyPlatform: string;
	isSurveyAvailable: boolean;
}

export interface AssessmentLinkInfo {
	assessmentLink: string;
	reportLink: string;
}

export interface Status {
	name: string;
	statusId: number;
}

export enum NavigationModes {
	BySection = "BySection",
	ByQuestionGroup = "ByQuestionGroup",
	ByQuestion = "ByQuestion",
}

export enum ScaleDisplayModes {
	AlignHorizontally = "AlignHorizontally",
	AlignVertically = "AlignVertically",
}

export interface ProgramStatus {
	programId: number;
	name: string;
	programName: string;
	programCode?: any;
	programStartDate: string;
	assessmentDueDate: string;
	instrumentStatuses: Array<InstrumentStatus>;
}

export type SectionReviewCard = {
	sectionId: string;
	heading: string;
	numberAnswered: number;
	notObservedAnswered: number;
	validationRule?: Rule;
	optional?: boolean;
	unansweredQuestions: Array<Question>;
	notObservedQuestions: Array<Question>;
	component: string;
};

export interface Participant {
	participantId: number;
	firstName: string;
	lastName: string;
}

export interface RaterType {
	raterTypeId: number;
	name: string;
}

export interface SurveyDocumentStateModel {
	surveyDocument: SurveyDocument;
	surveyAnswers: Array<SurveyAnswer>;
	questionList: Array<QuestionListItem>;
	participant: Participant;
	raterType: RaterType;
	//  componentDescriptors: Array<ComponentDescriptor>;
	surveySections: Array<SurveySection>;
	lastNavigationEvent: NavigationEvent;
	scrollTarget: number;
	answeringInProcess: boolean;
}

export interface ReviewQuestion {
	sectionId: string;
	formItemId: number;
}

export interface TabGroup {
	tabText: string;
	questionGroupIndexes: Array<number>;
	allAnswered: boolean;
}

export interface TabbedSectionStateModel {
	tabGroups: Array<TabGroup>;
	questionGroups: Array<QuestionGroup>;
	currentTabIndex: number;
}
export interface ScaleButton {
	buttonText: string;
	buttonValue: string;
	meaning?: string;
	reviewable?: boolean;
}

export interface ComponentDescriptor {
	component: string;
}

export interface SurveyDocumentStateModel {
	surveyDocument: SurveyDocument;
	surveyAnswers: Array<SurveyAnswer>;
	questionList: Array<QuestionListItem>;
	participant: Participant;
	raterType: RaterType;
	componentDescriptors: Array<ComponentDescriptor>;
	surveySections: Array<SurveySection>;
	lastNavigationEvent: NavigationEvent;
	scrollTarget: number;
	answeringInProcess: boolean;
}

export interface CompetencyReview {
	name: string;
	groups: Array<CompetencyReviewGroup>;
}

export interface CompetencyReviewGroup {
	name: string;
	questions: Array<Question>;
}

export interface QuestionListItem {
	sectionId: string;
	formItemId: number;
	formItemSequenceNumber: number;
	answer?: string;
	question?: Question;
}

export interface Gender {
	genderTypeId: string;
	description: string;
}

export interface Communication {
	email: string;
	businessPhone?: string;
	mobilePhone?: string;
}

export interface Individual {
	individualId: number;
	prefix?: string;
	firstName: string;
	middleInitials?: string;
	lastName: string;
	preferredName?: string;
	jobTitle?: string;
	preferredApplicationLanguageId: number;
	preferredApplicationLanguageCultureName?: string;
	preferredDateFormat: string;
}

export interface Profile {
	individual: Individual;
	communication: Communication;
	applicationLanguages: Array<Language>;
	preferredLanguageId: number;
	preferredLanguageCultureName?: string;
	isParticipant: boolean;
	isRater: boolean;
	landingPage: string;
	aboutMe: boolean;
}

export interface MyProfileResponse {
	profile: Profile;
}

export interface UnAnsweredQuestion {
	sectionIndex: number;
	groupIndex: number;
	questionIndex: number;
	section: string;
}

export interface SurveyUsage {
	id: number;
	instrumentKey: number;
	instrumentName: string;
	self: boolean;
	rater: boolean;
	surveyPlatform: string;
	active: boolean;
}
