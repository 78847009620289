import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { FormControl, InputLabel, Select } from "@mui/material";

import { RootState } from "../../../../store";
import { QuestionProps } from "../DisplayQuestion";
import { getSelectedAnswer } from "../../store/SurveyState";
import { useTranslationContext } from "../../../../context/TranslationContext";
import { FormattedPhrase } from "../../../../context/FormattedPhrase";
import { Question } from "../../../../@types/surveys.d";

export const SingleSelectComponent: React.FC<QuestionProps> = ({
	question,
	questionGroup,
	handleResponse,
}) => {
	const { activeAnswers, selectedLanguage, localSurveyLanguage } = useSelector((state: RootState) => state.user);
	const { getFormattedPhrase } = useTranslationContext();
	const [answer, setAnswer] = useState<string>("");

	useEffect(() => {
		if (question) {
			let a =
				getSelectedAnswer &&
				getSelectedAnswer(question.formItemId, activeAnswers);
			setAnswer(a ?? "");
		}
	}, [question, activeAnswers]);

	const removeDuplicates = (options: any) => {
		var dups = {};
		return options.filter(function(el) {
			var hash = el.text.valueOf();
			var isDup = dups[hash];
			dups[hash] = true;
			return !isDup;
		});
	}

	const getAgeList = (question: Question) => {
		if (question.formItemId === 1026242) {
			let ageList:Array<any> = [{"value": 0, "text": getFormattedPhrase("surveys.radioButton.desc2", null, localSurveyLanguage)}];
			for (let i=5; i<=110; i++) {
				ageList.push({"value": i, "text": i});
			}
			return ageList;
		}
		return [];
	}

	return (
		<FormControl fullWidth sx={{ 
			marginTop: 2,
			'& label': {
				transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
				left: 'inherit !important',
				right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
			},
			'& legend': {
				textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
			}
		}}>
			{/* <InputLabel sx={{ fontSize: "0.75rem" }} id="single-select">
				<FormattedPhrase id="surveys.radioButton.desc1" language={localSurveyLanguage} />
			</InputLabel> */}
			<Select
				native
				id="demo-simple-select"
				// label={<FormattedPhrase id="surveys.radioButton.desc1" language={localSurveyLanguage} />}
				variant="outlined"
				size="small"
				value={answer}
				onChange={(e) => {
					handleResponse &&
						handleResponse(
							question.formItemId,
							question.formItemSequenceNumber,
							e.target.value,
							false
						);
				}}
				sx={{width: "75%"}}
			>
				{question.optionGroups ?
					question.optionGroups.map((group, gindex) => {
						return (
							<Fragment key={gindex}>
								<option selected={answer === ""}><FormattedPhrase id="surveys.radioButton.desc2" language={localSurveyLanguage} /></option>
								{removeDuplicates(group?.options).map((option, oindex) => {
									return (
										<option key={oindex} value={option.value}>
											{option.text}
										</option>
									);
								})}
								{questionGroup?.answerGroup?.map((option, oindex) => {
									return (
										<option key={oindex} value={option.value}>
											{option.text}
										</option>
									);
								})}
							</Fragment>
						)})
					: getAgeList(question)?.map((option, gindex) => {
						return (
							<Fragment key={gindex}>
								return (
									<option key={gindex} value={option.value}>
										{option.text}
									</option>
								);
							</Fragment>
						)})
				}
			</Select>
		</FormControl>
	);
};
