import {
	Card,
	CardContent,
	CardHeader,
	Grid,
	Link,
	Typography,
} from "@mui/material";

import { Link as RouterLink, useLocation } from "react-router-dom";
import { useEnv } from "../context/env.context";
import { useAuth0 } from "@auth0/auth0-react";

export const Error: React.FC = () => {
	const { logout } = useAuth0();

	const { webAppUrl } = useEnv();

	return (
		<Grid
			container
			alignItems="center"
			justifyContent={"center"}
			sx={{ backgroundColor: "#1f222c", height: "100vh" }}
		>
			<Grid item xs={4}>
				<Card>
					<CardHeader title="Assessment Portal: System Issue" />
					<CardContent>
						<Typography variant="body1">
							<p>
								An issue has occurred in the system. This could be because you
								are trying to login and do not have the correct access or
								because the application is having difficulty connecting to CCL
								systems at this time. We recommend waiting a few minutes and
								then trying to access the application again.
							</p>
							<p>
								If the problem persists, please visit our{" "}
								<a href="https://support.ccl.org/home/" target="_blank">
									support page
								</a>{" "}
								for more information.
							</p>
							<Link
								to="#"
								component={RouterLink}
								onClick={() => {
									logout({
										// returnTo: "http://" + window.location.host,
										returnTo: webAppUrl,
									});
								}}
							>
								<Typography variant="subtitle1">Retry</Typography>
							</Link>
						</Typography>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};

export default Error;
