import React from "react";
import { Env } from "../models/env";

const envSettings = window as any;

const domain: string | undefined = envSettings.REACT_APP_AUTH0_DOMAIN ?? "";
const clientId: string | undefined = envSettings.REACT_APP_AUTH0_CLIENT_ID ?? "";
const ap1ClientId: string | undefined = envSettings.REACT_AP1_APP_AUTH0_CLIENT_ID ?? "";
const webAppUrl: string | undefined = envSettings.REACT_APP_WEB_APP_URL ?? "";
const apiServerUrl: string | undefined = envSettings.REACT_APP_CCL_API_MANAGEMENT_URL ?? "";
const applicationId: string | undefined = envSettings.REACT_APP_APPLICATION_ID ?? "";
const ap1ApplicationId: string | undefined = envSettings.REACT_AP1_APP_APPLICATION_ID ?? "";
const tenantId: string | undefined = envSettings.REACT_APP_TENANT_ID ?? "";
const environment: string | undefined = envSettings.ENVIRONMENT ?? "";

const isEnvValid = domain && clientId && webAppUrl && applicationId && tenantId && apiServerUrl && ap1ClientId && ap1ApplicationId;

if (!isEnvValid) {
    throw new Error("Missing environment variables.");
}

const dotenv: Env = {
    domain: domain,
    clientId: clientId,
    ap1ClientId: ap1ClientId,
    webAppUrl: webAppUrl,
    apiServerUrl: apiServerUrl,
    applicationId: applicationId as string,
    ap1ApplicationId: ap1ApplicationId as string,
    tenantId: tenantId as string,
    environment: environment as string,
};

export const EnvContext = React.createContext<Env>(dotenv);

export const useEnv = () => {
    const context = React.useContext(EnvContext);
    if (!context) {
        throw new Error(`useEnv must be used within a EnvProvider`);
    }
    return context;
};

export const EnvProvider: React.FC = (props) => {
    return (
        <EnvContext.Provider value={dotenv} {...props} />
    );
}
