import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { PropsWithChildren, useState } from "react";
import { useHistory } from "react-router-dom";
import { useEnv } from "./context/env.context";

export const Auth0ProviderWithHistory = ({
    children,
}: PropsWithChildren<any>): JSX.Element | null => {
    const history = useHistory();
    const { domain, clientId, applicationId, tenantId, ap1ClientId, ap1ApplicationId } = useEnv();
    const [redirectStatus, setRedirectStatus] = useState(localStorage.getItem("redirectOnLogOut"));
    const [redirectUrl, setRedirectUrl] = useState(localStorage.getItem("returnToAp1"));

    const onRedirectCallback = (appState: AppState | undefined) => {
        if (redirectStatus === "true" && redirectUrl) {
            history.push(redirectUrl);
        }
        else {
            history.push(appState?.returnTo || window.location.pathname);
        }
    };

    if (!(domain && clientId && ap1ClientId)) {
        return null;
    }

    return (
        redirectStatus === "true"
        ?
        <Auth0Provider
            domain={domain}
            clientId={ap1ClientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            scope={`openid profile loginApplicationId-${ap1ApplicationId}`}
        >
            {children}
        </Auth0Provider>
        :
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            scope={`openid profile email loginApplicationId-${applicationId} tenantId-${tenantId} signup-false`}
        >
            {children}
        </Auth0Provider>
    );
};