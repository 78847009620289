import { useEffect, useState } from "react";
import {
	Button,
	Card,
	Divider,
	Grid,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { Loading } from "../../components";
import { DisplayInstramentLink } from "./DisplayProgramInstrumentStatus";
import moment from "moment";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { useTranslationContext } from "../../context/TranslationContext";
import { FormattedPhrase } from "../../context/FormattedPhrase";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

type PastSurveyProps = {
	programStatuses: any;
};

export const PastSurveys: React.FC<PastSurveyProps> = ({
	programStatuses
}) => {
	const theme = useTheme();
	const { dateFormat } = useSelector((state: RootState) => state.user);
	const [busy, setBusy] = useState(false);
	const [raterSurveys, setRaterSurveys] = useState([]);
	const [sortDirection, setSortDirection] = useState(false);
	const [sortKey, setSortKey] = useState("firstName");
	const [open, setOpen] = useState(true);
	const { getFormattedPhrase } = useTranslationContext();

	useEffect(() => {
		if (programStatuses.length > 0) {
			extractRequiredFields(programStatuses);
		}
	}, [programStatuses]);

	useEffect(() => {
		// console.log(raterSurveys)
	}, [raterSurveys, sortDirection])

	const extractRequiredFields = (survey: any) => {
		let newArray: any = [];
		survey.map(x => {
			let completedDate = x.assessmentCompletedDate;
			completedDate = moment(completedDate).format(dateFormat);
			newArray.push({
				"participantId": x.participant.participantId, 
				"firstName": x.participant.firstName, 
				"lastName": x.participant.lastName, 
				"programId": x.instrument.instrumentId, 
				"program": x.instrument.name, 
				"assessment": "n/a", 
				"dateCompleted": completedDate, 
				"surveyStatusId": x.surveyStatusId
			});
		})
		setRaterSurveys(newArray);
	}

	const sortList = (key: string, direction: boolean) => {
		// true is ascending
		setSortDirection(!direction);
		setSortKey(key);
		let sortableItems = raterSurveys.sort((a, b) => {
			if (a[key] < b[key]) {
				return direction === true ? -1 : 1;
			}
			if (a[key] > b[key]) {
				return direction === true ? 1 : -1;
			}
			return 0;
			});
		setRaterSurveys(sortableItems);
	};

	const getDate = (dateCompleted: any) => {
		switch(dateCompleted) {
			case "Invalid date": return <FormattedPhrase id="pastSurveys.validationMessage.invalidDate"/>;
			break;
			default: return dateCompleted;
		}
	}

	return (
		<>
			{busy && <Loading />}
			{raterSurveys?.length > 0 && (
				<Grid sx={{ borderRadius: "0.75rem" }}>
					<Stack pt={2} pb={6} direction="row">
						<Typography variant="title">
							<FormattedPhrase id="dashboard.header.pastSurveys" />
						</Typography>
						<Divider flexItem sx={{ flex: 1, borderBottomColor: theme.palette.info.dark, marginX: 3, alignSelf: "center", marginTop: 2 }} />
						<KeyboardArrowUpOutlinedIcon sx={{ transform: open ? "rotate(180deg)" : "rotate(0deg)", marginTop: 1 }} onClick = {() => setOpen(!open)} />
					</Stack>
					{open ?
					<Card>
						<table>
							<thead style={{ borderBottom: "1px", borderBottomColor: "#ccc", marginLeft: "25px"}}>
								<tr>
								<th>
									<Button sx={{ color: theme.palette.text.primary, alignSelf: "flex-start" }} onClick={() => sortList("firstName", sortDirection)}>
										<Typography variant="body1" fontWeight={700} textAlign={"left"}>{getFormattedPhrase("raterManagement.addRater.placeholder.firstName")}</Typography>
										<ArrowDropUpIcon sx={{ transform: sortKey === "firstName" && sortDirection ? "rotate(180deg)" : "rotate(0deg)" }} />
									</Button>
								</th>
								<th>
									<Button sx={{ color: theme.palette.text.primary }} onClick={() => sortList("lastName", sortDirection)}>
										<Typography variant="body1" fontWeight={700} textAlign={"left"}>{getFormattedPhrase("raterManagement.addRater.placeholder.lastName")}</Typography>
										<ArrowDropUpIcon sx={{ transform: sortKey === "lastName" && sortDirection ? "rotate(180deg)" : "rotate(0deg)" }} />
									</Button>
								</th>
								<th>
									<Button sx={{ color: theme.palette.text.primary}} onClick={() => sortList("program", sortDirection)}>
										<Typography variant="body1" fontWeight={700} textAlign={"left"}>
											<FormattedPhrase id="surveys.assessment" />
										</Typography>
										<ArrowDropUpIcon sx={{ transform: sortKey === "program" && sortDirection ? "rotate(180deg)" : "rotate(0deg)" }} />
									</Button>
								</th>
								<th>
									<Button sx={{ color: theme.palette.text.primary }} onClick={() => sortList("dateCompleted", sortDirection)}>
										<Typography variant="body1" fontWeight={700} textAlign={"left"}>
											<FormattedPhrase id="surveys.dateCompleted" />
										</Typography>
										<ArrowDropUpIcon sx={{ transform: sortKey === "dateCompleted" && sortDirection ? "rotate(180deg)" : "rotate(0deg)" }} />
									</Button>
								</th>
								<th></th>
								</tr>
							</thead>
							<tbody>
								{raterSurveys.map((status: any, index: number) => (
								<tr key={index}>
									<td>{status.firstName}</td>
									<td>{status.lastName}</td>
									<td>{status.program}</td>
									<td>{getDate(status.dateCompleted)}</td>
									<td>
										<DisplayInstramentLink
											participantId={status.participantId}
											instrumentId={status.instrumentId}
											statusId={status.surveyStatusId}
											surveyTypeId={1}
											category={"assessment"}
										/>	
									</td>
								</tr>
								))}
							</tbody>
						</table>
					</Card>
					: <></>}
				</Grid>
			)}
		</>
	);
};
