import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Language } from "../../@types/language.d";
import {
	InstrumentRaterResponse,
	ProgramRaterStatus,
	RaterType,
	SurveyStatus,
} from "../../@types/raters.d";
import {
	Profile,
	ProgramStatus,
	QuestionListItem,
	SurveyAnswer,
	SurveyResponse,
	SurveySection,
	SurveyUsage,
} from "../../@types/surveys.d";
import { User } from "../../@types/user.d";
import {
	getAllSurveyStatus,
	getApplicationLanguages,
	getSurveyByParticipantAndLanguage,
	getUserProfile,
	getSurveyUsage,
	getVideoURL,
	getSurveyAnswersById,
} from "../../services/apiService";
import { ApplicationMessage } from "../../@types/applicationMessage.d";

export const getSurvey = async (data: any) => {
	try {
		if (data.user) {
			let acctId =
				data.user["http://schemas.ccl.org/accounts/claims/account/id"];
			let survey = await getSurveyByParticipantAndLanguage(
				acctId,
				data.instrumentId,
				data.participantId ?? 0,
				data.languageId ?? 1,
				data.surveyTypeId ?? 0
			);
			return survey;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchSurvey = createAsyncThunk("survey.fetch", getSurvey);

export const getSurveyAnswers = async (data: any) => {
	// try {
	// 	let survey = await getSurveyAnswersById(surveyId);
	// 	return survey;
	// } catch (error) {
	// 	return Promise.reject(error);
	// }
	try {
		if (data.user) {
			let acctId =
				data.user["http://schemas.ccl.org/accounts/claims/account/id"];
			let survey = await getSurveyByParticipantAndLanguage(
				acctId,
				data.instrumentId,
				data.participantId ?? 0,
				data.languageId ?? 1,
				data.surveyTypeId ?? 0
			);
			return survey;
		}
	} catch (error) {
		return Promise.reject(error);
	}
};

export const fetchSurveyAnswers = createAsyncThunk("surveyAnswers.fetch", getSurveyAnswers);

export const fetchSurveyUsage = createAsyncThunk(
	"surveyUsage.fetch",
	async () => {
		try {
			let usageData = await getSurveyUsage();
			return usageData;
		} catch (error) {
			return Promise.reject(error);
		}
	}
);

export const fetchVideos = createAsyncThunk(
	"videos.fetch",
	async (language: string) => {
		try {
			const welcome = await getVideoURL(language, "welcome");
			const self = await getVideoURL(language, "self");
			const self360 = await getVideoURL(language, "360");
			const rater = await getVideoURL(language, "rater");
			const ext = await getVideoURL(language, "external");

			let videos = {
				welcome: {
					video: welcome?.videoURL,
					thumb1: welcome?.thumbURLs[0],
					thumb2: welcome?.thumbURLs[1],
					subtitle: welcome?.subtitleURL
				},
				self: {
					video: self?.videoURL,
					thumb: self?.thumbURLs[0],
					subtitle: self?.subtitleURL
				},
				self360: {
					video: self360?.videoURL,
					thumb: self360?.thumbURLs[0],
					subtitle: self360?.subtitleURL
				},
				rater: {
					video: rater?.videoURL,
					thumb: rater?.thumbURLs[0],
					subtitle: rater?.subtitleURL
				},
				external: {
					video: ext?.videoURL,
					thumb: ext?.thumbURLs[0],
					subtitle: ext?.subtitleURL
				}
			}

			return videos;

		} catch (error) {
			return Promise.reject(error);
		}
	}
);

export const fetchProfile = createAsyncThunk(
	"profile.fetch",
	async (data: any) => {
		try {
			if (data.user) {
				let profile: Profile = await getUserProfile(data.user, data.languageId, data.token);
				profile.applicationLanguages = await getApplicationLanguages(profile.preferredLanguageId);
				return profile;
			}
		} catch (error) {
			return Promise.reject(error);
		}
	}
);

export const fetchProgramStatuses = createAsyncThunk(
	"programStatuses.fetch",
	async (acctId: string) => {
		try {
			let resp = await getAllSurveyStatus(acctId);
			return resp?.programInstrumentStatuses ?? [];
		} catch (error) {
			return Promise.reject(error);
		}
	}
);

const UserSlice = createSlice({
	name: "UserSlice",
	initialState: {
		activeAnswers: [] as Array<SurveyAnswer>,
		activeSurvey: {} as SurveyResponse,
		currentSection: {} as SurveySection,
		instrumentId: 0 as number,
		instrumentStatuses: [] as Array<ProgramStatus>,
		instrumentRaterResponse: {} as InstrumentRaterResponse,
		participantId: 0 as number,
		profileResponse: {} as Profile,
		programStatuses: [] as Array<ProgramStatus>,
		programRaterStatuses: {} as ProgramRaterStatus,
		questionList: [] as Array<QuestionListItem>,
		raterType: {} as RaterType,
		raterReminderLastSentAt: "",
		selectedLanguage: {
			cultureName: "en-US",
			direction: "ltr",
			languageId: 1,
		} as Language,
		selectedSurveyLanguage: {
			cultureName: "en-US",
			direction: "ltr",
			languageId: 1,
		} as Language,
		selectedSurveyType: 0 as number,
		surveyReopened: false,
		userProfile: {} as User,
		loading: false,
		localSurveyLanguage: "",
		conditionalQuestions: [] as Array<QuestionListItem>,
		surveyUsageData: [] as Array<SurveyUsage>,
		dateFormat: "MM/DD/yyyy",
		videos: {
			welcome: {
				video: "",
				thumb1: "",
				thumb2: "",
				subtitle: ""
			},
			self: {
				video: "",
				thumb: "",
				subtitle: "",
			},
			self360: {
				video: "",
				thumb: "",
				subtitle: ""
			},
			rater: {
				video: "",
				thumb: "",
				subtitle: ""
			},
			external: {
				video: "",
				thumb: "",
				subtitle: ""
			}
		},
		applicationMessage: {} as ApplicationMessage,
		userIdForAnalytics: "" as  string,
		raterInformationForRaterSurvey: {} as SurveyStatus
	},
	reducers: {
		setActiveAnswers(state, action: PayloadAction<string>) {
			state.activeAnswers = JSON.parse(action.payload);
		},
		setActiveSurvey(state, action: PayloadAction<SurveyResponse>) {
			state.activeSurvey = action.payload;
		},
		setCurrentSection(state, action: PayloadAction<SurveySection>) {
			state.currentSection = action.payload;
		},
		setInstrumentId(state, action: PayloadAction<number>) {
			state.instrumentId = action.payload;
		},
		setInstrumentStatuses(state, action: PayloadAction<string>) {
			state.instrumentStatuses = JSON.parse(action.payload);
		},
		setInstrumentRaterResponse(
			state,
			action: PayloadAction<InstrumentRaterResponse>
		) {
			state.instrumentRaterResponse = action.payload;
		},
		setParticipantId(state, action: PayloadAction<number>) {
			state.participantId = action.payload;
		},
		setProfileResponse(state, action: PayloadAction<Profile>) {
			state.profileResponse = action.payload;
		},
		setProgramStatuses(state, action: PayloadAction<string>) {
			state.programStatuses = JSON.parse(action.payload);
		},
		setQuestionList(state, action: PayloadAction<Array<QuestionListItem>>) {
			state.questionList = action.payload;
		},
		setProgramRatersStatuses(state, action: PayloadAction<ProgramRaterStatus>) {
			state.programRaterStatuses = action.payload;
		},
		setRaterType(state, action: PayloadAction<RaterType>) {
			state.raterType = action.payload;
		},
		setRaterReminderLastSentAt(state, action: PayloadAction<string>) {
			state.raterReminderLastSentAt = action.payload;
		},
		setSelectedLanguage(state, action: PayloadAction<Language>) {
			state.selectedLanguage = action.payload;
		},
		setSelectedSurveyLanguage(state, action: PayloadAction<Language>) {
			state.selectedLanguage = action.payload;
		},
		setSelectedSurveyType(state, action: PayloadAction<number>) {
			state.selectedSurveyType = action.payload;
		},
		setSurveyReopened(state, action: PayloadAction<boolean>) {
			state.surveyReopened = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setLocalSurveyLanguage(state, action: PayloadAction<string>) {
			state.localSurveyLanguage = action.payload;
		},
		setConditionalQuestions(state, action: PayloadAction<Array<QuestionListItem>>) {
			state.conditionalQuestions = action.payload;
		},
		setDateFormat(state, action: PayloadAction<string>) {
			state.dateFormat = action.payload;
		},
		setApplicationMessage(state, action: PayloadAction<ApplicationMessage>) {
			state.applicationMessage = action.payload;
		},
		setUserIdForAnalytics(state, action: PayloadAction<string>) {
			state.userIdForAnalytics = action.payload;
		},
		setRaterInformationForRaterSurvey(state, action: PayloadAction<SurveyStatus>) {
			state.raterInformationForRaterSurvey = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchSurvey.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(fetchSurvey.fulfilled, (state, action) => {
			let sr = action.payload as SurveyResponse;
			state.activeSurvey = sr;
			state.activeAnswers = sr.surveyAnswers;
			// console.log(sr.surveyAnswers);
			state.loading = false;
		});

		builder.addCase(fetchSurvey.rejected, (state, action) => {
			state.activeSurvey = {} as SurveyResponse;
			state.loading = false;
		});

		builder.addCase(fetchSurveyAnswers.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(fetchSurveyAnswers.fulfilled, (state, action) => {
			let sr = action.payload as SurveyResponse;
			state.activeAnswers = sr.surveyAnswers;
			state.loading = false;
		});

		builder.addCase(fetchVideos.fulfilled, (state, action) => {
			state.videos = action.payload;
		});

		// Get User Profile
		builder.addCase(fetchProfile.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(fetchProfile.fulfilled, (state, action) => {
			let res = action.payload as Profile;
			state.profileResponse = res;
			state.dateFormat = res?.individual?.preferredDateFormat === "2" ? "DD/MM/yyyy" : "MM/DD/yyyy";
			state.loading = false;
		});

		builder.addCase(fetchProfile.rejected, (state, action) => {
			state.profileResponse = {} as Profile;
			state.loading = false;
		});

		// Get Survey Usage
		builder.addCase(fetchSurveyUsage.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(fetchSurveyUsage.fulfilled, (state, action) => {
			let res = action.payload as Array<SurveyUsage>;
			state.surveyUsageData = res;
			state.loading = false;
		});

		builder.addCase(fetchSurveyUsage.rejected, (state, action) => {
			state.surveyUsageData = {} as Array<SurveyUsage>;
			state.loading = false;
		});

		// Get Program Statuses
		builder.addCase(fetchProgramStatuses.pending, (state) => {
			state.loading = true;
		});

		builder.addCase(fetchProgramStatuses.fulfilled, (state, action) => {
			let res = action.payload as Array<ProgramStatus>;
			state.programStatuses = res;
			state.loading = false;
		});

		builder.addCase(fetchProgramStatuses.rejected, (state, action) => {
			state.programStatuses = [];
			state.loading = false;
		});
	},
});

export const {
	setActiveAnswers,
	setActiveSurvey,
	setCurrentSection,
	setInstrumentId,
	setInstrumentStatuses,
	setInstrumentRaterResponse,
	setParticipantId,
	setProfileResponse,
	setProgramStatuses,
	setQuestionList,
	setProgramRatersStatuses,
	setRaterType,
	setRaterReminderLastSentAt,
	setSelectedLanguage,
	setSelectedSurveyLanguage,
	setSelectedSurveyType,
	setSurveyReopened,
	setLoading,
	setLocalSurveyLanguage,
	setConditionalQuestions,
	setDateFormat,
	setApplicationMessage,
	setUserIdForAnalytics,
	setRaterInformationForRaterSurvey
} = UserSlice.actions;

export default UserSlice.reducer;
