import React from "react";

import { Box, Button, Modal, Toolbar, Typography } from "@mui/material";

import components from "../../theme/components";
import { FormattedPhrase } from "../../context/FormattedPhrase";

type AlertModalProps = {
	isOpen: boolean;
	onClose: () => void;

	text?: string;
};

export const AlertModal: React.FC<AlertModalProps> = ({ isOpen, onClose, text }) => {
	return (
		<Modal open={isOpen} onClose={onClose}>
			<Box sx={components.MuiModal}>
				<Typography variant="h3" my={2}>
				</Typography>
				<Box>
					<Typography variant="subtitle1">{text}</Typography>
				</Box>
				<Toolbar sx={{ justifyContent: "flex-end" }}>
					<Button onClick={onClose}><FormattedPhrase id="dialog.ok"/></Button>
				</Toolbar>
			</Box>
		</Modal>
	);
};
