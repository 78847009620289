import { Button, Stack } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid";
import React from "react";
import FlexGrid from "../../../components/shared/flexGrid";
import { assessmentFormLanguage } from "../../../rtkquery/types/assessmentFormLanguage";
import { assessmentUsage } from "../../../rtkquery/types/assessmentUsage";
import { useHistory } from "react-router-dom";

interface AssessmentContentProps {
    assessmentFormLanguageData: assessmentFormLanguage[] | undefined;
    isLoading: boolean;
    assessmentUsage: assessmentUsage | undefined;
}

export const FormMastersDetail: React.FC<AssessmentContentProps> = (props) => {
	
	const history = useHistory();
	const openBtnClicked = (row: any) => {
		history.push("/assessment-management/" + props.assessmentUsage?.instrumentKey + "/" + row.formMasterKey);
	}
	
	const columns: GridColDef[] = [
		{ field: 'formMasterKey', headerName: 'FormMasterKey', width: 120,  minWidth:120 },
		{ field: 'formMasterName', headerName: 'Name', flex: 1 },
		{ field: 'sLanguage', headerName: 'Language', width: 200, minWidth:200 },
    { field: 'cultureName', headerName: 'Culture', width: 200, minWidth:200 },
    {
			field: "openBtn",
			headerName: " ",
			width: 100,
			renderCell: (params: GridRenderCellParams) => (
				<Button size={"small"} variant={"outlined"} onClick={() => openBtnClicked(params.row)}>
					Open
				</Button>
			)
		}
	];

  function getRowId(row) {
    return row.formMasterKey;
  }

	return (
    
        <Stack gap={2}>
            <FlexGrid>
              <div
              style={{
                height: '700px',
                width: '100%',
                overflow: 'auto',
              }}
              >
                <DataGrid
                  getRowId={getRowId} 
                  rows={props.assessmentFormLanguageData} columns={columns} 
                  loading={props.isLoading} 
                  slots={{ toolbar: GridToolbar }}
                  />
                </div>
            </FlexGrid>  
        </Stack>
    
        		
	);
};
