import { jwtDecode } from "jwt-decode";
import { Groups } from "../models/groups";

export class UserServices {
    
    private isNullOrEmptyString(str: string): boolean {
        return str === "undefined" || str === null || str === "";
    }

    private getDecodedTokenClaim(key: string): any {
        const token = localStorage.getItem("token") as string;
        if (this.isNullOrEmptyString(token)) return null;

        const decoded: any = jwtDecode(token);
        if (decoded == null || this.isNullOrEmptyString(decoded[key])) return "";

        return decoded[key];
    }

    public isAssessmentManager(): boolean {
        const groupidsStr: string = this.getDecodedTokenClaim(
            "http://schemas.ccl.org/accounts/claims/group/ids"
        );
        if (this.isNullOrEmptyString(groupidsStr)) return false;
        const groupIds: string[] = groupidsStr.split(";");
        return this.isInGroup(groupIds, [Groups.AssessmentManager]);
    }

    private isInGroup(userGroups: string[], roleList: string[]): boolean {
        const roleListUpper: string[] = roleList.map((r) => r.toUpperCase());
        return userGroups.some((gid) => roleListUpper.includes(gid.toUpperCase()));
    }
    
}