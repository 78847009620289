import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import { Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import {
	setCurrentGroupIndex,
	setCurrentTabIndex,
	setGoBack,
} from "../../store/Navigation/NavSlice";
import { setCurrentSection } from "../../store/User/UserSlice";
import { useEffect, useState } from "react";
import { FormattedPhrase } from "../../context/FormattedPhrase";

const TabGroup: React.FC = () => {
	const { currentSection, localSurveyLanguage } = useSelector((state: RootState) => state.user);
	const { mainTabGroup } = useSelector((state: RootState) => state.navigation);
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const [index, setIndex] = useState<number>(0);

	const dispatch = useDispatch();

	const theme = useTheme();

	useEffect(() => {
		if (currentSection && mainTabGroup) {
			let currentIndex = mainTabGroup.findIndex((x) => {
				return x.sectionId == currentSection.sectionId;
			});
			setIndex(currentIndex);
			if (index < currentIndex) {
				setSelectedIndex(currentIndex);
			}
		}
	}, [currentSection, index, selectedIndex]);

	const changeSection = (x, i) => {
		dispatch(setGoBack(true));
		window.scrollTo(0, 0);
		dispatch(setCurrentSection(x));
		dispatch(setCurrentGroupIndex(-1));
		dispatch(setCurrentTabIndex(-1));

		if (selectedIndex < i) {
			setSelectedIndex(selectedIndex + 1);
			setIndex(index + 1);
		}
	};

	return (
		<Grid container direction="row" alignSelf="center" justifyContent="center">
			{mainTabGroup.length &&
				mainTabGroup.map((item, index) => {
					return (
						<Button
							key={index}
							className={
								currentSection.sectionId === item.sectionId
									? "tab-group tab-border"
									: "tab-group"
							}
							sx={{ backgroundColor: theme.palette.secondary.light, "&:hover": {
								backgroundColor: theme.palette.secondary.light,
								borderRadius: "5px",
							}, }}
							variant="text"
							//	disabled={selectedIndex < index}
							onClick={() => changeSection(item, index)}
						>
							<Typography
								sx={{
									fontWeight: currentSection.sectionId === item.sectionId ? 'bold' : 500,
									color: theme.palette.text.primary,
								}}
								key={index}
								variant="caption"
							>
								{
									item.component.includes("SurveyReviewQuestionsComponent")
										? (item.heading ? item.heading : <FormattedPhrase id="navigation.review" language={localSurveyLanguage} />)
										: item.heading
								}
							</Typography>
						</Button>
					);
				})}
		</Grid>
	);
};

export default TabGroup;
