import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import { Box, Grid, Typography } from "@mui/material";
import {
	setAllowNext,
	setAllowPrevious,
	setTabs,
} from "../../../store/Navigation/NavSlice";
import { RootState } from "../../../store";
import { SurveySectionProps } from "./SurveySectionComponent";

import "./SurveySection.scss";

export const SurveyReviewQuestionComponent: React.FC<SurveySectionProps> =
	({}) => {
		const dispatch = useDispatch();
		const { currentGroupIndex, showSectionSubheading, currentNavigationMode } =
			useSelector((state: RootState) => state.navigation);
		const { currentSection } = useSelector((state: RootState) => state.user);

		useEffect(() => {
			dispatch(setAllowNext(false));
			dispatch(setAllowPrevious(true));

			if (currentSection?.questionGroups === null) {
				dispatch(setTabs([]));
			}
		}, [currentSection]);

		return (
			<Fragment>
				<Box sx={{ flexGrow: 1, ml: 3, mr: 3 }} className="question-card">
					<Grid container spacing={2}>
						<Grid item md={12}>
							{currentSection?.heading && (
								<Typography
									gutterBottom
									variant="subtitle2"
									fontWeight={900}
									component="div"
									className="p-3 question-title"
									sx={{
										px: 5,
										pt: 5,
										pb: currentSection?.subheadings !== null ? 3 : 0,
									}}
								>
									{currentSection.heading}
								</Typography>
							)}
							<Fragment>
								{showSectionSubheading && (
									<div className="section-subheading">
										{currentSection?.subheadings?.map((item, index) => {
											return (
												<Typography
													gutterBottom
													key={index}
													variant="h6"
													component="div"
													className="p-3 text-left question-subtitle"
												>
													{item}
												</Typography>
											);
										})}
									</div>
								)}
							</Fragment>
							<Fragment>
								{currentSection?.information?.map((item, index) => {
									return (
										<Fragment key={index}>
											<Box sx={{ flexGrow: 1, m: 5 }}>
												<Typography
													gutterBottom
													variant="subtitle1"
													component="div"
													className="p-3 text-left question-title"
													key={index}
												>
													<ReactMarkdown rehypePlugins={[rehypeRaw]}>
														{item}
													</ReactMarkdown>
												</Typography>
											</Box>
										</Fragment>
									);
								})}
							</Fragment>
						</Grid>
					</Grid>
				</Box>
			</Fragment>
		);
	};
