import { Shadows } from "@mui/material/styles/shadows";

function createShadow() {
    return `box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);`;
}

function createShadow2(level: number) {
    return `box-shadow: ${level}px ${level * 2}px ${level}px rgba(0, 0, 0, .25);`;
}

const shadows: Shadows = [
    "none",
    createShadow(),
    createShadow(),
    createShadow2(1),
    createShadow2(1),
    createShadow2(1),
    createShadow2(1),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
];

export default shadows;
