import { Box, Breadcrumbs, Card, CardContent, Link, Stack, Tab, Tabs } from "@mui/material";
import { Layout } from "../../components/chrome/Layout";
import usePageTitle from "../../hooks/pageTitle";
import React from "react";
//import { AssessmentSummary } from "./components/AssessmentSummary";
import { FormMastersDetail} from "./components/FormMastersDetail";
import { useGetAssessmentFormLanguagesQuery, useGetAssessmentUsageQuery } from "../../rtkquery/apis/assessmentManagementApi";
import { UserServices } from "../../services/userService";
import { Error } from "../Error";

export type AssessmentDetailParams = {
    id: string;
};

export const AssessmentDetailPage: React.FC = () => {
	const [value, setValue] = React.useState(0);
	const userService = new UserServices();

	const id = () => {
		var pathName = window.location.pathname;
		var pathArray = pathName.split('/');
		var id = pathArray[2];
		return parseInt(id);
	}
	
	const {data: assessmentUsageData} = useGetAssessmentUsageQuery();
	const { data: assessmentLanguageData, isLoading } = useGetAssessmentFormLanguagesQuery(id());

	const selectedAssessment = assessmentUsageData?.find(assessment => assessment.instrumentKey === id());

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};
	
	interface TabPanelProps {
		children?: React.ReactNode;
		dir?: string;
		index: number;
		value: number;
	}

	// function AssessmentSummaryTabPanel(props: TabPanelProps) {
	// 	const { children, value, index, ...other } = props;
	// 	return (
	// 		<div
	// 		role="tabpanel"
	// 		hidden={value !== index}
	// 		id={`full-width-tabpanel-${index}`}
	// 		aria-labelledby={`full-width-tab-${index}`}
	// 		{...other}
	// 		>
	// 		{value === index && (
	// 			<AssessmentSummary />
	// 		)}
	// 		</div>
	// 	);
	// }

	function FormMasterTabPanel(props: TabPanelProps) {
		const { children, value, index, ...other } = props;
		return (
			<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
			>
			{value === index && (
				<FormMastersDetail assessmentFormLanguageData={assessmentLanguageData} 
				assessmentUsage={selectedAssessment} isLoading={isLoading} />
			)}
			</div>
		);
	}
	
		
	const getTitle = () => {
		return "Assessment Detail | Center for Creative Leadership";
	}
	usePageTitle(getTitle());

	const getHref = () => {
		return "/assessment-management/" + id().toString();
	}

	if (!userService.isAssessmentManager()) {
		return ( <Error /> );
	}

	return (
		<Layout>
			<Card>
				<CardContent>
				<Stack>
					<Box sx={{paddingBottom: 5}}>
					    <Breadcrumbs aria-label="breadcrumb">
						<Link underline="hover" color="inherit" href="/assessment-management">
                            Assessment Management
                            </Link>
                            <Link
                            underline="hover"
                            color="text.primary"
                            href={getHref()}
                            aria-current="page"
                            >
                            {selectedAssessment?.instrumentName}
                            </Link>
                        </Breadcrumbs>
					</Box>
					<Tabs value={value}
						onChange={handleTabChange}>
						<Tab label="Form Masters" />
						
					</Tabs>
					
					<FormMasterTabPanel value={value} index={0} />
					
				</Stack>
				</CardContent>
			</Card>
		</Layout>
	);
};
