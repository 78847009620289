import { Fragment, useEffect, useState } from "react";

import { Liquid } from "liquidjs";

import { useTranslationContext } from "./TranslationContext";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";

import "./FormattedPhrase.scss";

type FormattedPhraseProps = {
	id: string;
	isHtml?: boolean;
	values?: any;
	language?: string;
};

export const FormattedPhrase: React.FC<FormattedPhraseProps> = ({
	id,
	isHtml,
	values,
	language,
}) => {
	const { chosenLanguage, getFormattedPhrase } = useTranslationContext();

	const [phrase, setPhrase] = useState<string>("");

	// For when we need it
	const engine = new Liquid();

	useEffect(() => {
		let formattedPhrase = getFormattedPhrase(
			id,
			values,
			language ?? chosenLanguage
		);
		if (values) {
			const tpl = engine.parse(formattedPhrase);
			engine.render(tpl, values).then((rendered) => {
				setPhrase(rendered);
			});
		} else {
			setPhrase(formattedPhrase);
		}
	}, [chosenLanguage]);

	return (
		<Fragment>
			{isHtml ? (
				<ReactMarkdown className="reactMarkDown" rehypePlugins={[rehypeRaw]}>
					{phrase}
				</ReactMarkdown>
			) : (
				<Fragment>{phrase}</Fragment>
			)}
		</Fragment>
	);
};
