import {
	TabGroup,
	TabbedSectionStateModel,
	QuestionListItem,
	Question,
	QuestionGroup,
	SurveySection,
} from "../../../@types/surveys.d";

const NUMBER_OF_TABS: number = 5;

const getTabs = (
	surveySection: SurveySection,
	questionList: Array<QuestionListItem>
) => {
	const tabGroups: any[] = buildTabGroups(surveySection);

	tabGroups.forEach((tab: TabGroup) => {
		let allAnswered: boolean = true;

		tab.questionGroupIndexes.forEach((questionGroupIndex: number) => {
			allAnswered =
				allAnswered &&
				surveySection?.questionGroups[questionGroupIndex].questions.every(
					(question: Question) =>
						questionList.some(
							(questionListItem: QuestionListItem) =>
								questionListItem.formItemId === question.formItemId &&
								questionListItem.answer &&
								questionListItem.answer !== null
						)
				);
		});

		if (tab.tabText === null || tab.tabText === "") {
			let start =
				surveySection.questionGroups[tab.questionGroupIndexes[0]].questions[0]
					.number;
			let lastQuestionGroup: QuestionGroup =
				surveySection.questionGroups[
					tab.questionGroupIndexes[tab.questionGroupIndexes.length - 1]
				];
			let end =
				lastQuestionGroup.questions[lastQuestionGroup.questions.length - 1]
					.number;
			tab.tabText = `${start} - ${end}`;
		}

		tab.allAnswered = allAnswered;
	});

	return tabGroups;
};

const setQuestionGroups = (
	state: TabbedSectionStateModel,
	surveySection: SurveySection
) => {
	const start = state.tabGroups[state.currentTabIndex].questionGroupIndexes[0];
	const end =
		state.tabGroups[state.currentTabIndex].questionGroupIndexes[
			state.tabGroups[state.currentTabIndex].questionGroupIndexes.length - 1
		] + 1;

	const currentQuestionGroups: QuestionGroup[] =
		surveySection.questionGroups.slice(start, end);

	return currentQuestionGroups;
};

export const getGroupsPerTab = (surveySection: SurveySection) => {
	return Math.ceil(surveySection?.questionGroups?.length / NUMBER_OF_TABS);
};

export const buildTabGroups = (surveySection: SurveySection) => {
	let tabGroups: Array<TabGroup> = [];

	// validate that all groups are either custom tab names or not
	let groupsWithCustomTabText: Array<QuestionGroup> =
		surveySection?.questionGroups?.filter(
			(questionGroup: QuestionGroup) =>
				questionGroup.tabText && questionGroup?.tabText?.length > 0
		);
	if (
		groupsWithCustomTabText?.length != 0 &&
		groupsWithCustomTabText?.length != surveySection?.questionGroups?.length
	) {
		throw new Error(
			"Attempting to use Custom tab text but not all question groups have tab text defined."
		);
	}

	// build tabs with custom text
	if (groupsWithCustomTabText?.length > 0) {
		surveySection?.questionGroups?.forEach(
			(questionGroup: QuestionGroup, index: number) => {
				let tab = tabGroups.find(
					(tab: TabGroup) => tab.tabText === questionGroup.tabText
				);
				if (tab) {
					tab.questionGroupIndexes.push(index);
				} else {
					tabGroups.push({
						tabText: questionGroup.tabText ?? "",
						questionGroupIndexes: [index],
						allAnswered: true,
					});
				}
			}
		);
	} else {
		//build tabs out of question groups
		// const groupsPerTab = getGroupsPerTab(surveySection);
		const groupsPerTab = 1;

		if (groupsPerTab === 1) {
			// if here there are 1-5 question groups so the final tab array will have 1-5 tabs
			surveySection?.questionGroups?.forEach(
				(questionGroup: QuestionGroup, index: number) => {
					tabGroups.push({
						tabText: null ?? "",
						questionGroupIndexes: [index],
						allAnswered: true,
					});
				}
			);
		} else {
			// things get a little crazy with groups being spread out evenly over tabs
			let start: number = 0;
			let end: number = groupsPerTab - 1;

			for (let i = 0; i < NUMBER_OF_TABS; i++) {
				let indexes: Array<number> = [];
				for (let j = start; start < end; j++) {
					indexes.push(j);
					start += 1;
				}

				if (
					i < surveySection?.questionGroups?.length % NUMBER_OF_TABS ||
					surveySection?.questionGroups?.length % NUMBER_OF_TABS === 0
				) {
					indexes.push(start);
					start += 1;
				}

				end = start + (groupsPerTab - 1);

				tabGroups.push({
					tabText: null ?? "",
					questionGroupIndexes: [...indexes],
					allAnswered: true,
				});
			}
		}
	}

	return tabGroups;
};

const buildMainTabGroups = (surveySections: SurveySection[]) => {
	let mainTabGroup: Array<SurveySection> = [];

	surveySections.map((section) => {
		if (
			(section?.questionGroups?.length >= 1 &&
				section?.component !== "SurveyWelcomeComponent") ||
			section?.component.includes("SurveySectionIntroductionComponent") ||
			section?.component.includes("SurveyCompetenciesReviewComponent") ||
			section?.component.includes("SurveyReview") 
		) {
			mainTabGroup.push(section);
		}
	});
	return mainTabGroup;
};

export { getTabs, setQuestionGroups, buildMainTabGroups };
