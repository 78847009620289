import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FormControl, FormControlLabel, Radio, Typography } from "@mui/material";

import { QuestionProps } from "../DisplayQuestion";
import { RootState } from "../../../../store";
import { Question } from "../../../../@types/surveys.d";
import { getSelectedAnswer } from "../../store/SurveyState";
import { saveItemResponse } from "../../../../services/apiService";
import { setActiveAnswers, setLoading } from "../../../../store/User/UserSlice";
import { useTranslationContext } from "../../../../context/TranslationContext";
import { AlertModal } from "../../../shared/alertModal";

export const SurveyAOrBQuestionsComponent: React.FC<QuestionProps> = ({
	question,
}) => {
	const dispatch = useDispatch();
	const [isMBTIQ, setIsMBTIQ] = useState(true);
	const { activeAnswers, activeSurvey, instrumentId } = useSelector(
		(state: RootState) => state.user
	);
	const { getFormattedPhrase } = useTranslationContext();
	const [alertOpen, setAlertOpen] = useState(false);

	const handleAorBResponse = async (question: Question, formItemId: number, value: string) => {
		try {
			dispatch(setLoading(true));
			//MBTIQ and Thomas-Kilmann Conflict Mode Instrument, ISI surveys need 0 / 1 stored instead of 1 / 2
			//but some logic is shared by both surveys and some aren't
			const isMBTIQorTKI = (activeSurvey.surveyDocument.survey.instrumentId === 637) || (activeSurvey.surveyDocument.survey.instrumentId === 1923);
			const isTKIorISI = (activeSurvey.surveyDocument.survey.instrumentId === 1923 || activeSurvey.surveyDocument.survey.instrumentId === 1897);
			const isMBTIQ = (activeSurvey.surveyDocument.survey.instrumentId === 637);
			const isStepII =  activeSurvey.surveyDocument.survey.instrumentId === 1841;
			let thisOption = question?.options?.find(
				(o) => (isMBTIQorTKI || isStepII) ? o.value === value : o.formItemId === formItemId
			);
			let otherOption = question?.options?.find(
				(o) => (isMBTIQorTKI || isStepII) ? o.value !== value : o.formItemId !== formItemId
			);
			let saveThisResponse = await saveItemResponse(
				activeSurvey.surveyDocument.id,
				instrumentId,
				isStepII ? question.formItemId : thisOption?.formItemId ?? question.formItemId,
				thisOption?.formItemSequenceNumber ?? question.formItemSequenceNumber,
				(isMBTIQ || isStepII) ? thisOption?.value! : "1"
			);
			let saveOtherResponse = await saveItemResponse(
				activeSurvey.surveyDocument.id,
				instrumentId,
				isStepII ? question.formItemId : otherOption?.formItemId ?? question.formItemId,
				otherOption?.formItemSequenceNumber ?? question.formItemSequenceNumber,
				(isMBTIQ || isStepII) ? thisOption?.value! : isTKIorISI ? "0" : "2"
			);

			let updated = [...activeAnswers];
			updated.push({
				surveyDocumentId: activeSurvey.surveyDocument.id,
				formItemId: saveThisResponse.answers[0].formItemId,
				formItemSequenceNumber: thisOption?.formItemSequenceNumber ?? 0,
				optional: saveThisResponse.answers[0].optional,
				answer: saveThisResponse.answers[0].answer,
				answeredTimeMs: saveThisResponse.answers[0].answeredTimeMs,
			});
			!isMBTIQ && updated.push({
				surveyDocumentId: activeSurvey.surveyDocument.id,
				formItemId: saveOtherResponse.answers[0].formItemId,
				formItemSequenceNumber: thisOption?.formItemSequenceNumber ?? 0,
				optional: saveOtherResponse.answers[0].optional,
				answer: saveOtherResponse.answers[0].answer,
				answeredTimeMs: saveOtherResponse.answers[0].answeredTimeMs,
			});
			dispatch(setActiveAnswers(JSON.stringify(updated)));
		}
		catch (exception) {
			// same old set of answers
			let updated = [...activeAnswers];
			dispatch(setActiveAnswers(JSON.stringify(updated)));
			setAlertOpen(true);
		} finally {
			dispatch(setLoading(false));
		}
	};

	useEffect(() => {
		const checkMBTIQ = (activeSurvey.surveyDocument.survey.instrumentId === 637 || activeSurvey.surveyDocument.survey.instrumentId === 1841);
		if (isMBTIQ !== checkMBTIQ) {
			//update state only if necessary!
			setIsMBTIQ(checkMBTIQ);
		}
	}, [question, activeAnswers]);

	return (
		<FormControl>
			<AlertModal text={getFormattedPhrase("error.savingResponse")} onClose={() => setAlertOpen(false)} isOpen={alertOpen} />
			{question?.text ?
			<Typography mt={3} mb={2} textAlign={"left"}>{question?.text}</Typography> : <></>}
			{question.options?.map((option, index) => {
				let a = getSelectedAnswer(isMBTIQ ? question.formItemId : option.formItemId ?? 0, activeAnswers);
				return (
					<FormControlLabel
						key={index}
						value={option.value}
						control={
							<Radio
								color={"secondary"}
								name={`radio-group-${question.formItemId}`}
								checked={a === (isMBTIQ ? option.value : "1")}
								onChange={async (e) => {
									await handleAorBResponse(question, option?.formItemId ?? 0, option.value);
								}}
							/>
						}
						label={`${option.text}`}
					/>
				);
			})}
		</FormControl>
	);
};
