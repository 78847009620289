import React, { useEffect, useRef } from "react";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Modal,
	useTheme,
} from "@mui/material";
import IdleTimer from "react-idle-timer";
import { useEnv } from "../../context/env.context";
import { FormattedPhrase } from "../../context/FormattedPhrase";
import { useInterval } from "../../utils/useInterval";
import { useTranslationContext } from "../../context/TranslationContext";
import components from "../../theme/components";
import { ConfirmationModal } from "../shared/confirmationModal";

// SessionTime Out Component(Used - React Idle Timer)
const oneMinute = 60000;

const SessionTimeOut = (props) => {
	const { getFormattedPhrase } = useTranslationContext();
	const theme = useTheme();
	const idleTime = useRef(null);
	const [isOpen, setIsOpen] = React.useState(false);
	const [timeLeft, setTimeLeft] = React.useState<number>(59);
	const [warning, setWarning] = React.useState<string>();
	const idleTimeOut = useRef<any>(null);

	const { domain } = useEnv();

	// OnIdle
	const onIdle = () => {
		setIsOpen(true);
		idleTimeOut.current = setTimeout(() => {
			logOut();
		}, oneMinute);
	};

	//Stay Active
	const stayActive = () => {
		setIsOpen(false);
		setTimeLeft(59);
		clearTimeout(idleTimeOut.current);
	};

	//LogOut
	const logOut = () => {
		window.location.href = "/logout";
	};

	useInterval(
		() => {
			setTimeLeft(timeLeft - 1);
		},
		isOpen ? 1000 : null
	);

	useEffect(() => {
		let phrase = getFormattedPhrase("dialog.sessionTimeout.warning");
		setWarning(phrase.replace("{{timeLeft}}", `0:${timeLeft}`));
	});

	useEffect(() => {
		if (isOpen) {
			document.title = "" + warning;
			console.log(`logging out in ${timeLeft} seconds`);
		} else {
			document.title = "Assessments | Center for Creative Leadership";
		}
	}, [isOpen, timeLeft]);

	let timeoutVal = 15 * oneMinute;
	if (domain === "auth-dev.ccl.org") timeoutVal = 720 * oneMinute;

	const checkStyle = (defaultAction: boolean) => {
		let bgColor = theme.palette.primary.main;
		let btnTextColor = theme.palette.secondary.contrastText;
		let borderWidth = 1;

		if (defaultAction){
			bgColor = theme.palette.primary.main;
			btnTextColor = theme.palette.primary.contrastText;
		}
		else {
			bgColor = theme.palette.error.main;
			btnTextColor = theme.palette.secondary.contrastText;
			borderWidth = 0;
		}

		return {
			backgroundColor: bgColor,
			color: btnTextColor,
			borderRadius: "100px",
			borderWidth: borderWidth,
			mr: 2,
		}
	}

	return (
		<div>
			<ConfirmationModal
				isOpen={isOpen}
				title={getFormattedPhrase("dialog.sessionTimeout.title")}
				promptText={warning}
				yesText={getFormattedPhrase("dialog.yes")}
				defaultButton={getFormattedPhrase("dialog.yes")}
				dangerButton={getFormattedPhrase("dialog.no")}
				noText={getFormattedPhrase("dialog.no")}
				onYes={stayActive}
				onNo={logOut}
			/>
			<IdleTimer
				ref={idleTime as any}
				timeout={timeoutVal}
				onIdle={onIdle}
			></IdleTimer>
		</div>
	);
};

export default SessionTimeOut;
