import { Fragment, useEffect } from "react";

import { Auth0Context, Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useEnv } from "../context/env.context";

export const Logout: React.FC = () => {
	const { logout } = useAuth0();

	const { webAppUrl, ap1ClientId, clientId } = useEnv();

	useEffect(() => {
		let redirectUrl = localStorage.getItem("returnToAp1");
		let redirectUser = localStorage.getItem("redirectOnLogOut");

		let returnToUrl = webAppUrl;

		if (redirectUser === "true" && redirectUrl) {
			// redirect user only if they have come from AP1
			localStorage.setItem("redirectUser", "");
			localStorage.setItem("returnToAp1", "");
			returnToUrl = redirectUrl;
		}

		localStorage.clear();
		logout({
			returnTo: returnToUrl,
			client_id: redirectUser === "true" ? ap1ClientId : clientId
		});
	}, []);

	return <Fragment></Fragment>;
};
