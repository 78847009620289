import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { RootState } from "../../store";
import { setActiveSurvey, setProgramStatuses, setSurveyReopened } from "../../store/User/UserSlice";
import { completeSurvey, getAllSurveyStatus } from "../../services/apiService";
import Loading from "../chrome/Loading";

import { useTranslationContext } from "../../context/TranslationContext";

import "./SurveySubmit.scss";
import { current } from "@reduxjs/toolkit";
import { setMainTabGroup } from "../../store/Navigation/NavSlice";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEnv } from "../../context/env.context";

type SurveySubmitProps = {
	valid: boolean;
};

export const SurveySubmit: React.FC<SurveySubmitProps> = ({ valid }) => {
	const dispatch = useDispatch();

	const { user } = useAuth0();
	const { domain } = useEnv();

	const { activeSurvey, currentSection, instrumentId, localSurveyLanguage, userIdForAnalytics } = useSelector(
		(state: RootState) => state.user
	);

	const { canSubmit } = useSelector((state: RootState) => state.navigation);

	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [hasReviewableSections, setHasReviewableSections] =
		useState<boolean>(false);

	const [yesText, setYesText] = useState<string>("");
	const [noText, setNoText] = useState<string>("");
	const [submitText, setSubmitText] = useState<string>("");
	const [submitConfirmText, setSubmitConfirmText] = useState<string>("");

	const { getFormattedPhrase } = useTranslationContext();

	const getModalMessage = (currSec: any) => {
		const submitConfirmText = currSec.extensions.find((x) => x.name === "submitConfirm")?.value;
		const confirmationBodyText = currSec.extensions.find((x) => x.name === "confirmationBody")?.value;
		if (submitConfirmText?.trim()) {
			return submitConfirmText;
		} else if (confirmationBodyText?.trim()) {
		 	return confirmationBodyText;
		} else {
			return getFormattedPhrase("surveys.submitConfirm", null, localSurveyLanguage);
		}
	}

	useEffect(() => {
		if (activeSurvey?.surveyDocument?.survey?.surveySections) {
			let has = false;
			activeSurvey.surveyDocument.survey.surveySections.forEach(
				(section) => (has = has || section.reviewable)
			);
			setHasReviewableSections(has);
		}
	}, [activeSurvey]);

	useEffect(() => {
		if (currentSection.extensions) {
			setYesText(
				getFormattedPhrase("dialog.yes", null, localSurveyLanguage)
			);
			setNoText(
				getFormattedPhrase("dialog.no", null, localSurveyLanguage)
			);

			if (currentSection.submitButtonText) {
				setSubmitText(currentSection.submitButtonText);
			} else {
				setSubmitText(
					currentSection.extensions.find((x) => x.name === "submitButton")
						?.value ?? getFormattedPhrase("backgroundLanding.button.save", null, localSurveyLanguage)
				);
			}
			
			setSubmitConfirmText(getModalMessage(currentSection));
		}
	}, [currentSection]);

	const onSubmit = async (evt: any) => {
		setModalOpen(true);
	};

	const onCompleteSurvey = async () => {
		setModalOpen(false);
		if (user) {
			let newSurvey = await completeSurvey(
				activeSurvey.surveyDocument.id,
				instrumentId
			);

			logEvent(getAnalytics(),
				`[SUBMIT] ${activeSurvey.surveyDocument.survey.name} Survey${activeSurvey.surveyDocument.survey.surveyTypeId != 0 ? " (Rater)" : ""}`,
				{
					"Taken As": (activeSurvey.surveyDocument.survey.surveyTypeId === 0) ? "Participant" : "Rater",
					"User": (userIdForAnalytics)
				}
			);

			//duplicate event, adding for 78676
			logEvent(getAnalytics(),
				"Survey Submitted",
				{
					"Survey Name": activeSurvey.surveyDocument.survey.name,
					"Taken As": (activeSurvey.surveyDocument.survey.surveyTypeId === 0) ? "Participant" : "Rater",
					"User": (userIdForAnalytics)
				}
			);

			// get the updated status after submitting
			let acctId = user["http://schemas.ccl.org/accounts/claims/account/id"];
			let resp = await getAllSurveyStatus(acctId);
			dispatch(
				setProgramStatuses(
					JSON.stringify(resp?.programInstrumentStatuses ?? [])
				)
			);
			dispatch(setActiveSurvey(newSurvey));
			dispatch(setSurveyReopened(false));
			dispatch(setMainTabGroup([]));
			setSubmitting(false);
		}
	};

	const confirmSubmit = async (evt: any) => {
		setSubmitting(true);
		try {
			onCompleteSurvey();
		} catch (error) {
			// Log for now
			console.error(error);

			// And retry
			console.log("Retrying...");
			setTimeout(() => {
				onCompleteSurvey();
			}, 1000);
			setSubmitting(false);
		}
	};

	return (
		<Fragment>
			{submitting && <Loading />}
			{!hasReviewableSections ? (
				// if not reviewable, this is just a submit screen.
				<Button
					variant="contained"
					size="large"
					className={"mx-1"}
					onClick={(e) => onSubmit(e)}
					tabIndex={3}
					sx={{ m: 1, borderRadius: 50 }}
				>
					{submitText}
				</Button>
			) : (
				<Button
					variant="contained"
					size="large"
					className={!valid || !canSubmit ? "reviewable mx-1 disabled" : "mx-1"}
					disabled={!valid || !canSubmit}
					onClick={(e) => onSubmit(e)}
					tabIndex={3}
					sx={{ m: 1, borderRadius: 50 }}
				>
					{submitText}
				</Button>
			)}

			<Dialog open={modalOpen}>
				<DialogContent>
					<DialogContentText>{submitConfirmText}</DialogContentText>
				</DialogContent>
				<DialogActions>
					{submitting ? (
						<Typography variant="body2" sx={{ m: 1 }}>
							{getFormattedPhrase("surveys.submitting", null, localSurveyLanguage)}
						</Typography>
					) : (
						<Button
							disabled={submitting}
							onClick={confirmSubmit}
							variant="contained"
							size="small"
							className="btn-style"
							color="primary"
							sx={{ m: 1 }}
						>
							{yesText}
						</Button>
					)}
					<Button
						disabled={submitting}
						onClick={() => setModalOpen(false)}
						variant="outlined"
						size="small"
						className="btn-style"
						sx={{ m: 1 }}
					>
						{noText}
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
};
