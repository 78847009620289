import "./Loading.scss";

interface LoadingProps {
    height?: number;
    width?: number;
}

const Loading: React.FC<LoadingProps> = ({ height, width }) => (
    <div className="loading">
        <div className="ball"></div>
        <div className="ball1"></div>
    </div>
);

export default Loading;
