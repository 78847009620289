import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigationModes, QuestionGroup } from "../../../@types/surveys.d";

import { RootState } from "../../../store";
import { setCurrentQuestionIndex } from "../../../store/Navigation/NavSlice";
import { DisplayQuestion } from "./DisplayQuestion";
import { Typography } from "@mui/material";
import { getAnswerCount, getRequiredAnswersCount } from "../store/SurveyState";
import { FormattedPhrase } from "../../../context/FormattedPhrase";

type QuestionGroupProps = {
	questionGroup: QuestionGroup;
	centerQuestions: boolean;
	hideNumber?: boolean;
};

export const DisplayQuestionGroupComponent: React.FC<QuestionGroupProps> = ({
	questionGroup,
	centerQuestions,
	hideNumber,
}) => {
	const dispatch = useDispatch();

	const { currentNavigationMode, currentQuestionIndex, checkedCount } =
		useSelector((state: RootState) => state.navigation);
	const { currentSection, localSurveyLanguage } = useSelector((state: RootState) => state.user);
	const [requiredCount, setRequiredCount] = useState<number>(0);

	useEffect(() => {
		if (questionGroup?.questions?.length > 0 && currentQuestionIndex < 0) {
			dispatch(setCurrentQuestionIndex(0));
		}
	}, [questionGroup, currentQuestionIndex]);

	useEffect(() => {
		if (
			currentSection &&
			currentSection?.component === "SurveyCompetenciesQuestionsComponent"
		) {
			let requiredAnswers = getRequiredAnswersCount(
				currentSection?.validationRule
			);
			setRequiredCount(requiredAnswers);
		}
	}, [currentSection, checkedCount]);

	const renderByNavigationMode = () => {
		switch (currentNavigationMode) {
			case NavigationModes.ByQuestion:
				return (
					<Fragment>
						<DisplayQuestion
							question={questionGroup?.questions[currentQuestionIndex]}
							questionGroup={questionGroup}
							centerQuestions={centerQuestions}
							hideNumber={hideNumber}
						/>
					</Fragment>
				);

			case NavigationModes.ByQuestionGroup:
			case NavigationModes.BySection:
			default:
				return (
					<Fragment>
						{questionGroup?.questions?.map((question, index) => {
							return (
								<Fragment key={index}>
									<DisplayQuestion
										question={question}
										questionGroup={questionGroup}
										centerQuestions={centerQuestions}
										hideNumber={hideNumber}
									/>
								</Fragment>
							);
						})}
					</Fragment>
				);
		}
	};

	const renderQuestionGroup = () => {
		return (
			<Fragment>
				{questionGroup && <Fragment>{renderByNavigationMode()}</Fragment>}
			</Fragment>
		);
	};

	return (
		<div className="question-group">
			{currentSection.component === "SurveyCompetenciesQuestionsComponent" ? (
				<Typography textAlign={"center"} fontWeight={700}>
					{checkedCount}/{requiredCount} <FormattedPhrase id="checkBox.number.selected" language={localSurveyLanguage} />
				</Typography>
			) : (
				<></>
			)}
			<Fragment>
				{questionGroup?.heading?.text && (
					<Typography variant="subtitle2" sx={{ fontWeight: "700", mt: 5, mb: 2 }}>
						{questionGroup?.heading?.text}
					</Typography>
				)}

				{renderQuestionGroup()}
			</Fragment>
		</div>
	);
};
