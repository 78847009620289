import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Box, Grid, Modal, Stack, Typography, Toolbar, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {
	NavigationModes,
	QuestionListItem,
	ScaleDisplayModes,
	SurveyDocumentStateModel,
	SurveySection,
	TabbedSectionStateModel,
	TabGroup,
} from "../../../@types/surveys.d";
import { RootState } from "../../../store";
import { DisplayQuestionGroupComponent } from "../questions/DisplayQuestionGroupComponent";
import { SurveySectionProps } from "./SurveySectionComponent";
import {
	setCurrentGroupIndex,
	setCurrentTabIndex,
	setMainTabGroup,
	setScaleDisplayMode,
	setTabs,
} from "../../../store/Navigation/NavSlice";
import { FormattedPhrase } from "../../../context/FormattedPhrase";
import InfoIcon from '@mui/icons-material/Info';
import "./SurveySection.scss";
import components from "../../../theme/components";
import { getTabs } from "../store/TabbedSectionState";
import { isHTML } from "../../../hooks/common";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";

export const SharedSurveyQuestionsComponent: React.FC<SurveySectionProps> = ({
	centerQuestions,
}) => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const {
		currentGroupIndex,
		currentTabIndex,
		showSectionSubheading,
		currentNavigationMode,
		tabs,
	} = useSelector((state: RootState) => state.navigation);
	const { currentSection, questionList, activeSurvey, raterInformationForRaterSurvey } = useSelector(
		(state: RootState) => state.user
	);
	const [modalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		if (currentSection?.questionGroups?.length > 0 && currentGroupIndex < 0) {
			dispatch(setCurrentGroupIndex(0));
		}
	}, [currentGroupIndex]);

	useEffect(() => {
		if (currentSection && questionList) {
			if (currentTabIndex === -1) {
				dispatch(setCurrentTabIndex(0));
			}
			// Remove tabs for assessments that don't use them
			switch (activeSurvey?.surveyDocument?.survey?.instrumentId) {
				case 2947: // Workplace Big 5 - 5.0 settings
					dispatch(setScaleDisplayMode(ScaleDisplayModes.AlignHorizontally));
					break;
				case 2948: // REAL Companion settings
					dispatch(setTabs([]));
					break;

				default:
					updateTabs(currentSection, questionList);
			}
		}
	}, [currentSection, currentTabIndex]);

	const updateTabs = (
		currentSection: SurveySection,
		questionList: QuestionListItem[]
	) => {
		let tabbedSections = getTabs(currentSection, questionList);

		if (tabbedSections.length) {
			if (tabbedSections.length === 1) {
				dispatch(setTabs([]));
				return;
			}
			dispatch(setTabs(tabbedSections));
		}
	};

	return (
		<Fragment>
			<Box
				sx={{ flexGrow: 1, ml: 3, mr: 3, mb: 7 }}
				className={
					tabs.length ? "question-card extra-padding" : "question-card"
				}
			>
				{
					(raterInformationForRaterSurvey && Object.keys(raterInformationForRaterSurvey).length > 0) &&
						<Box
							sx={{ flexGrow: 1, ml: 3, mr: 3, mt: 4 }}
							className="review-info"
						>
							<Grid container paddingY={2}>
								<Grid item md={12}>
									<Fragment>
										<Stack direction="row" paddingX={3}>
											<InfoIcon
												sx={{ mr: 3, color: theme.palette.primary.main }}
											/>
											<Typography variant="h6" fontWeight={400}>
												{/* {getFormattedPhrase("surveys.submitReview", null, localSurveyLanguage)} */}
												You are rating as {raterInformationForRaterSurvey.participant.firstName} {raterInformationForRaterSurvey.participant.lastName}'s {raterInformationForRaterSurvey.rater.raterType.name}.
											</Typography>
										</Stack>
									</Fragment>
								</Grid>
							</Grid>
						</Box>
				}
				<Grid container spacing={2} mt={window.innerWidth < 400 ? 5 : 0}>
					<Grid item md={12}>
						<Stack direction="row">
							{currentSection.heading && (
								<Typography
									gutterBottom
									variant="subtitle2"
									fontWeight={700}
									component="div"
									className="p-3 question-title"
									sx={{
										px: 7,
										pt: 5,
										pb: currentSection?.subheadings !== null ? 1 : 0,
									}}
								>
									{currentSection.heading}
								</Typography>
							)}
							{currentSection.information &&
							<InfoIcon
								sx={{
									color: theme.palette.primary.main,
									width: 22,
									height: 22,
									marginTop: 3,
									cursor: "pointer"
								}}
								onClick={() => setModalOpen(!modalOpen)}
							/>}
						</Stack>
						{currentSection.information &&
							<Box sx={{ mt: 5, ml: 7 }}>
								<Modal
									open={modalOpen}
									onClose={() => setModalOpen(false)}
								>
									<Box sx={[components.MuiModal]}>
										<Toolbar sx={{ justifyContent: "flex-end" }}>
											<Button onClick={() => setModalOpen(false)}>
												<CloseIcon/>
											</Button>
										</Toolbar>
										{currentSection.information.map((item, index) => {
											return(
											<Box sx={{ minHeight: item.length > 75 ? "25%" : "15%"}} key={index}>
												<Typography
													gutterBottom
													variant="h6"
													component="div"
													className="p-3 text-left question-title"
													pb={2}
												>
													<ReactMarkdown rehypePlugins={[rehypeRaw]}>
														{item}
													</ReactMarkdown>
												</Typography>
											</Box>
											);
										})}
									</Box>
								</Modal>
							</Box>
						}
						<Fragment>
							{showSectionSubheading && currentSection?.subheadings !== null ? (
								<div className="section-subheading">
									{currentSection?.subheadings?.map((item, index) => (
										<Typography
											gutterBottom
											key={index}
											variant="h6"
											component="div"
											className={
												isHTML(item)
													? "p-3 text-left question-subtitle-html"
													: "p-3 text-left question-subtitle"
											}
											sx={{ px: 2 }}
										>
											{isHTML(item) ? (
												<ReactMarkdown rehypePlugins={[rehypeRaw]}>
													{item}
												</ReactMarkdown>
											) : (
												<label>{item}</label>
											)}
										</Typography>
									))}
								</div>
							) : (
								<></>
							)}
							{currentSection?.questionGroups && (
								<Fragment>
									{currentNavigationMode === NavigationModes.ByQuestion && (
										<DisplayQuestionGroupComponent
											questionGroup={
												currentSection?.questionGroups[currentGroupIndex]
											}
											centerQuestions={centerQuestions}
										/>
									)}
									{currentNavigationMode ===
										NavigationModes.ByQuestionGroup && (
										<Fragment>
											<DisplayQuestionGroupComponent
												questionGroup={
													currentSection?.questionGroups[currentGroupIndex]
												}
												centerQuestions={centerQuestions}
											/>
										</Fragment>
									)}
									{currentNavigationMode === NavigationModes.BySection && (
										<Fragment>
											{currentSection?.questionGroups.map((group, index) => {
												return (
													<Fragment key={index}>
														<DisplayQuestionGroupComponent
															questionGroup={group}
															centerQuestions={centerQuestions}
														/>
													</Fragment>
												);
											})}
										</Fragment>
									)}
								</Fragment>
							)}
						</Fragment>
					</Grid>
				</Grid>
			</Box>
		</Fragment>
	);
};
