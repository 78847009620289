import { createContext, useContext } from "react";

export type TranslationContextProps = {
	chosenLanguage: string;
	setChosenLanguage: (c: string) => void;
	getFormattedPhrase: (c: string, values?: any, l?: string) => string;
};

export const TranslationContext = createContext<TranslationContextProps>({
	chosenLanguage: "en-US", // set a default value
	setChosenLanguage: (c: string) => {},
	getFormattedPhrase: (c: string, v?: any, l?: string) => "not found",
});

export const useTranslationContext = () => useContext(TranslationContext);
