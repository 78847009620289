import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	TextField,
	Typography,
} from "@mui/material";

import { Option, Question } from "../../../../@types/surveys.d";

import { RootState } from "../../../../store";
import { QuestionProps } from "../DisplayQuestion";
import {
	getSelectedAnswer,
	getSelectedAnswerArray,
} from "../../store/SurveyState";
import { clearItemResponse } from "../../../../services/apiService";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

type DependentOptionProps = {
	option: Option;

	handleResponse?: (
		itemId: number,
		sequenceNum: number,
		response: string,
		append: boolean
	) => void;
};

const DependentOptionInput: React.FC<DependentOptionProps> = ({
	option,
	handleResponse,
}) => {
	const { activeAnswers, selectedLanguage } = useSelector((state: RootState) => state.user);

	const [selectedAnswer, setSelectedAnswer] = useState<string>("");
	const updateAnswer = (str: string) => {
		let answer = str.substring(0, option.dependentOption.maxLength);

		setSelectedAnswer(answer);
	};
	useEffect(() => {
		if (option) {
			let a = getSelectedAnswer(
				option?.dependentOption.formItemId ?? 0,
				activeAnswers
			);
			setSelectedAnswer(a);
		}
	}, [option, activeAnswers]);
	return (
		<Fragment>
			<TextField
				id={`text_field_${option.formItemId}`}
				fullWidth
				size={"small"}
				multiline={true}
				rows={3}
				value={selectedAnswer}
				className="text-input"
				placeholder={option.dependentOption.placeholder}
				onBlur={(e) => {
					let a = getSelectedAnswer(
						option?.dependentOption.formItemId ?? 0,
						activeAnswers
					);
					if (e.target.value !== a)
						handleResponse &&
							handleResponse(
								option.dependentOption?.formItemId ?? 0,
								option.dependentOption?.formItemSequenceNumber ?? 0,
								e.target.value,
								false
							);
				}}
				sx={{ 
					marginTop: 0, marginBottom: 0, 
					'& label': {
						transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
						left: 'inherit !important',
						right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
					},
					'& legend': {
						textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
					}
				}}
				onChange={(e) => updateAnswer(e.target.value)}
				error={
					selectedAnswer?.length > (option.dependentOption.maxLength ?? 250)
				}
			/>
			<Grid container>
				<Grid item xs={12} style={{ textAlign: "right" }}>
					<Typography variant="caption">
						{selectedAnswer?.length ?? 0}/{option.dependentOption.maxLength}
					</Typography>
				</Grid>
			</Grid>
		</Fragment>
	);
};

type OptionProps = {
	question: Question;
	options: Array<Option>;
	index: number;
	useGroups: boolean;

	handleResponse?: (
		itemId: number,
		sequenceNum: number,
		response: string,
		append: boolean
	) => void;
};

const OptionInput: React.FC<OptionProps> = ({
	question,
	options,
	index,
	useGroups,
	handleResponse,
}) => {
	const { activeAnswers, activeSurvey } = useSelector(
		(state: RootState) => state.user
	);

	const [answerArray, setAnswerArray] = useState<Array<string>>([]);

	useEffect(() => {
		if (question) {
			let a =
				getSelectedAnswerArray &&
				getSelectedAnswerArray(question.formItemId, activeAnswers);
			setAnswerArray(a ?? []);
		}
	}, [question, activeAnswers]);

	return (
		<FormControl
			key={index}
			required
			component="fieldset"
			// sx={{ m: 3 }}
			variant="standard"
		>
			<FormGroup>
				{options?.map((option: Option, optindex: number) => {
					let answer = getSelectedAnswer(
						option?.formItemId ?? 0,
						activeAnswers
					);
					return (
						<Fragment>
							<FormControlLabel
								key={optindex}
								sx={{ mb: useGroups ? 0 : 5 }}
								control={
									<Checkbox
										color={"secondary"}
										name={`checkbox-group-${question.formItemId}`}
										checked={
											useGroups
												? answerArray && answerArray.indexOf(option.value) >= 0
												: answer === "1"
										}
										onChange={(e) => {
											if (handleResponse) {
												if (useGroups) {
													handleResponse(
														question.formItemId,
														question.formItemSequenceNumber,
														e.target.value,
														true
													);
												} else {
													handleResponse(
														option?.formItemId ?? 0,
														option?.formItemSequenceNumber ?? 0,
														e.target.checked ? "1" : "0",
														false
													);
													if (!e.target.checked && option.dependentOption) {
														// Clear the dependent option
														clearItemResponse(
															activeSurvey.surveyDocument.survey.id,
															activeSurvey.surveyDocument.survey.instrumentId,
															option.dependentOption?.formItemId ?? 0,
															option.dependentOption?.formItemSequenceNumber ??
																0
														);
													}
												}
											}
										}}
									/>
								}
								value={option.value}
								label={useGroups
									? 	<ReactMarkdown rehypePlugins={[rehypeRaw]}>
											{option.answer}
										</ReactMarkdown> 
								 	: option.text
								}
							/>
							{answer == "1" && option.dependentOption && (
								<DependentOptionInput
									option={option}
									handleResponse={handleResponse}
								/>
							)}
						</Fragment>
					);
				})}
			</FormGroup>
		</FormControl>
	);
};

export const MultiSelectComponent: React.FC<QuestionProps> = ({
	question,
	handleResponse,
}) => {
	return (
		<Fragment>
			{question.options && question.options?.length > 0 ? (
				<OptionInput
					question={question}
					options={question.options}
					index={0}
					useGroups={false}
					handleResponse={handleResponse}
				/>
			) : (
				<Fragment>
					{question.optionGroups?.map((group, index) => (
						<OptionInput
							index={index}
							key={index}
							question={question}
							options={group.options}
							useGroups={true}
							handleResponse={handleResponse}
						/>
					))}
				</Fragment>
			)}
		</Fragment>
	);
};
