import { Fragment, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
	Question,
	QuestionGroup,
	QuestionListItem,
	ScaleDisplayModes,
	SurveyAnswer,
	SurveySection,
} from "../../../@types/surveys.d";
import { RootState } from "../../../store";

import { SurveyWelcomeComponent } from "./SurveyWelcomeComponent";
import { SurveyQuestionsComponent } from "./SurveyQuestionsComponent";
import { SurveyReviewQuestionsComponent } from "./SurveyReviewQuestionsComponent";
import { SurveyCompleteComponent } from "./SurveyCompleteComponent";
import { SharedSurveyQuestionsComponent } from "./SharedSurveyQuestionsComponent";
import { SurveyReviewQuestionComponent } from "./SurveyReviewQuestionComponent";
import { SurveyCompetenciesReviewComponent } from "./SurveyCompetenciesReviewComponent";

import Loading from "../../chrome/Loading";
import { setConditionalQuestions, setQuestionList } from "../../../store/User/UserSlice";

import "./SurveySection.scss";
import {
	setCurrentGroupIndex,
	setCurrentTabIndex,
	setMainTabGroup,
	setScaleDisplayMode,
	setTabs,
} from "../../../store/Navigation/NavSlice";
import { buildMainTabGroups } from "../store/TabbedSectionState";

export type SurveySectionProps = {
	centerQuestions: boolean;
	surveySection?: SurveySection;
};

export const UnhandledSection: React.FC = ({}) => {
	const { currentSection } = useSelector((state: RootState) => state.user);

	return (
		<Fragment>
			<Card className="m-3">
				<Card.Title className="p-3 text-center">
					{currentSection.heading}
				</Card.Title>
				<Card.Body>{JSON.stringify(currentSection)}</Card.Body>
			</Card>
		</Fragment>
	);
};

export const SurveySectionComponent: React.FC<SurveySectionProps> = ({
	centerQuestions,
}) => {
	const dispatch = useDispatch();

	const { currentGroupIndex, currentTabIndex } = useSelector(
		(state: RootState) => state.navigation
	);
	const { activeAnswers, activeSurvey, currentSection, questionList,localSurveyLanguage } =
		useSelector((state: RootState) => state.user);

	// duplicate from SurveyCompetenciesReviewComponent but with a minor change
	const buildQuestionList = (
		surveySections: Array<SurveySection>,
		surveyAnswers: Array<SurveyAnswer>
	) => {
		let questionList: Array<QuestionListItem> = [];

		surveySections.forEach((surveySection: SurveySection) => {
			if (!surveySection.questionGroups) return;
			surveySection?.questionGroups?.forEach((questionGroup: QuestionGroup) => {
				if (!questionGroup.questions) return;

				questionGroup.questions.forEach((question: Question) => {
					let answers: Array<SurveyAnswer> = surveyAnswers
						.filter(
							(surveyAnswer: SurveyAnswer) =>
								surveyAnswer.formItemId === question.formItemId
						)
						.sort(
							(a: SurveyAnswer, b: SurveyAnswer) =>
								b.answeredTimeMs - a.answeredTimeMs
						); // sort in descending order

					if (!answers.length) {
						answers = activeAnswers
							.filter(
								(surveyAnswer: SurveyAnswer) =>
									surveyAnswer.formItemId === question.formItemId
							)
							.sort(
								(a: SurveyAnswer, b: SurveyAnswer) =>
									b.answeredTimeMs - a.answeredTimeMs
							); // sort in descending order
					}

					questionList.push({
						sectionId: surveySection.sectionId,
						formItemId: question.formItemId,
						formItemSequenceNumber: question.formItemSequenceNumber,
						answer: (answers.length > 0 ? answers[answers.length - 1].answer : null) as string,
						question: question,
					});

					if (question.dependentOption) {
						let answers: Array<SurveyAnswer> = surveyAnswers
							.filter(
								(surveyAnswer: SurveyAnswer) =>
									surveyAnswer.formItemId ===
									question.dependentOption.formItemId
							)
							.sort(
								(a: SurveyAnswer, b: SurveyAnswer) =>
									b.answeredTimeMs - a.answeredTimeMs
							); // sort in descending order

						if (!answers.length) {
							answers = activeAnswers
								.filter(
									(surveyAnswer: SurveyAnswer) =>
										surveyAnswer.formItemId === question.dependentOption.formItemId
								)
								.sort(
									(a: SurveyAnswer, b: SurveyAnswer) =>
										b.answeredTimeMs - a.answeredTimeMs
								); // sort in descending order
						}

						questionList.push({
							sectionId: surveySection.sectionId,
							formItemId: question.dependentOption.formItemId ?? 0,
							formItemSequenceNumber:
								question.dependentOption.formItemSequenceNumber ?? 0,
							answer: (answers.length > 0 ? answers[answers.length - 1].answer : null) as string,
							question: question,
						});
					}

					if (
						question.options &&
						question.options.some((option) => !!option.formItemId)
					) {
						question.options.forEach((option) => {
							let answers: Array<SurveyAnswer> = surveyAnswers
								.filter(
									(surveyAnswer: SurveyAnswer) =>
										surveyAnswer.formItemId === option.formItemId
								)
								.sort(
									(a: SurveyAnswer, b: SurveyAnswer) =>
										b.answeredTimeMs - a.answeredTimeMs
								); // sort in descending order

							if (!answers.length) {
								answers = activeAnswers
									.filter(
										(surveyAnswer: SurveyAnswer) =>
											surveyAnswer.formItemId === option.formItemId
									)
									.sort(
										(a: SurveyAnswer, b: SurveyAnswer) =>
											b.answeredTimeMs - a.answeredTimeMs
									); // sort in descending order
							}

							questionList.push({
								sectionId: surveySection.sectionId,
								formItemId: option.formItemId ?? 0,
								formItemSequenceNumber: option.formItemSequenceNumber ?? 0,
								answer: (answers.length > 0
									? answers[answers.length - 1].answer
									: null) as string,
							});

							if (option.dependentFormItemSequenceNumber) {
								let answers: Array<SurveyAnswer> = surveyAnswers
									.filter(
										(surveyAnswer: SurveyAnswer) =>
											surveyAnswer.formItemId ===
											option.dependentOption.formItemId
									)
									.sort(
										(a: SurveyAnswer, b: SurveyAnswer) =>
											b.answeredTimeMs - a.answeredTimeMs
									); // sort in descending order

								// for the time being this is set only for Org Challenge survey
								if (activeSurvey.surveyDocument.survey.instrumentId === 1920) {
									if (activeAnswers.length) {
										answers = activeAnswers
											.filter(
												(surveyAnswer: SurveyAnswer) =>
													surveyAnswer.formItemId === option.dependentOption.formItemId
											)
											.sort(
												(a: SurveyAnswer, b: SurveyAnswer) =>
													b.answeredTimeMs - a.answeredTimeMs
											); // sort in descending order
									}
								}
								
								questionList.push({
									sectionId: surveySection.sectionId,
									formItemId: option.dependentOption.formItemId ?? 0,
									formItemSequenceNumber:
										option.dependentOption.formItemSequenceNumber ?? 0,
									answer: (answers.length > 0
										? answers[answers.length - 1].answer
										: null) as string,
								});
							}
						});
					}
				});
			});
		});
		return questionList;
	};

	useEffect(() => {
		if (activeSurvey) {
			let questionList = buildQuestionList(
				activeSurvey?.surveyDocument.survey.surveySections,
				activeSurvey.surveyAnswers
			);
			let conditionalQuestionsList = questionList.filter((a) => a.question?.conditionalValidators?.length);
			conditionalQuestionsList = conditionalQuestionsList.filter((a) => a.question?.component === "TextInputComponent");
			dispatch(setQuestionList(questionList));
			dispatch(setConditionalQuestions(conditionalQuestionsList));
		}
	}, [activeSurvey, activeAnswers, localSurveyLanguage]);

	useEffect(() => {
		if (currentSection?.questionGroups?.length > 0 && currentGroupIndex < 0) {
			dispatch(setCurrentGroupIndex(0));
		}
	}, [currentGroupIndex]);

	useEffect(() => {
		if (currentSection && questionList) {
			if (currentTabIndex === -1) {
				dispatch(setCurrentTabIndex(0));
			}
			// Remove tabs for assessments that don't use them
			switch (activeSurvey?.surveyDocument?.survey?.instrumentId) {
				case 2947: // Workplace Big 5 - 5.0 settings
					dispatch(setScaleDisplayMode(ScaleDisplayModes.AlignHorizontally));
					break;
				case 2948: // REAL Companion settings
					dispatch(setTabs([]));
					break;

				default:
					updateTabs();
			}
		}
	}, [currentSection, currentTabIndex]);

	const updateTabs = () => {
		if (currentSection.component === "SurveyCompetenciesReviewComponent") {
			dispatch(setTabs([]));
		}
		if (currentSection.component === "SurveyWelcomeComponent") {
			dispatch(setMainTabGroup([]));
		} else {
			let tabGroup = buildMainTabGroups(
				activeSurvey?.surveyDocument?.survey?.surveySections
			);
			dispatch(setMainTabGroup(tabGroup));
		}
	};

	const renderSection = () => {
		switch (currentSection?.component) {
			case "SurveyWelcomeComponent":
			case "SurveySectionIntroductionComponent":
				return <SurveyWelcomeComponent intermediate={currentSection?.component === "SurveySectionIntroductionComponent"}/>;

			// TODO: SurveyQuestionsComponent might be able to use the SharedSurveyQuestionsComponent
			case "SurveyQuestionsComponent":
			case "SurveyDirectFeedbackTabbedQuestionsComponent":
			case "SurveyDirectFeedbackQuestionsComponent":
				return <SurveyQuestionsComponent centerQuestions={centerQuestions} />;

			case "SurveyScalesQuestionsComponent":
			case "SurveyMixedQuestionsComponent":
			case "SurveyDemographicsQuestionsComponent":
			case "SurveyAOrBQuestionsComponent":
			case "SurveyAAndBQuestionsComponent":
			case "SurveyCompetenciesQuestionsComponent":
				return (
					<SharedSurveyQuestionsComponent centerQuestions={centerQuestions} />
				);

			case "SurveyCompetenciesReviewComponent":
				return <SurveyCompetenciesReviewComponent />;

			case "SurveyReviewQuestionComponent":
				return (
					<SurveyReviewQuestionComponent centerQuestions={centerQuestions} />
				);

			case "SurveyReviewQuestionsComponent":
				return (
					<SurveyReviewQuestionsComponent centerQuestions={centerQuestions} />
				);

			case "SurveyCompleteComponent":
				return (
					<SurveyCompleteComponent
						surveySection={currentSection}
						centerQuestions={centerQuestions}
					/>
				);

			default:
				return <UnhandledSection />;
		}
	};

	return (
		<Fragment>
			{currentSection.sectionId ? renderSection() : <Loading />}
		</Fragment>
	);
};
