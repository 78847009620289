import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { useTheme } from "@mui/material/styles";

type TabProps = {
    tabs: Array<string>,
    activeTab: string,
    clickedTab(tabName: string): void
}

const TabSwitch: React.FC<TabProps> = ({tabs, activeTab, clickedTab}) => {
const theme = useTheme();

const onClickTab = (t: string) => {
    if (t !== activeTab) {
        clickedTab(t);
    }
}

  return (
    <Fragment>
        <Stack sx={{width: "fit-content", backgroundColor: "#FFFFFF", borderColor: theme.palette.secondary.main, borderWidth: 1, borderLeftWidth: 1, borderRightWidth: 1, borderStyle: "solid", flexDirection: "row", borderRadius: 10, overflow: "hidden", justifyContent: "space-between"}}>
            {tabs.map((t, index) => 
                <Button 
                    key={index}
                    sx={[
                        {borderRadius: 0, backgroundColor: activeTab === t ? theme.palette.secondary.main : theme.palette.primary.contrastText, px: 2, py: 1, borderColor: theme.palette.info.main, borderWidth: 0, borderStyle: "solid"},
                        (index!==0 && index!==tabs.length-1) && {borderRightWidth: 1, borderLeftWidth: 1}, 
                        (activeTab === t) && {":hover": {backgroundColor: theme.palette.secondary.main}}
                    ]}
                    onClick={() => onClickTab(t)}
                >
                    <Typography 
                        sx={activeTab === t ? {color: theme.palette.background.paper} : {color: theme.palette.info.contrastText}}
                    >
                        {t}
                    </Typography>
                </Button>
            )}
        </Stack>
    </Fragment>
  );
};

export default TabSwitch;