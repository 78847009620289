import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Grid, TextField, Typography } from "@mui/material";

import { RootState } from "../../../../store";
import { QuestionProps } from "../DisplayQuestion";
import { getSelectedAnswer } from "../../store/SurveyState";

import "../../../../pages/surveys/Surveys.scss";

export const TextComponent: React.FC<QuestionProps> = ({
	question,
	handleResponse,
}) => {
	const { activeAnswers, selectedLanguage } = useSelector((state: RootState) => state.user);

	const [selectedAnswer, setSelectedAnswer] = useState<string>("");
	const [optionalText, setOptionalText] = useState<string>("");
	const [maxStringLength, setMaxStringLength] = useState(0);

	useEffect(() => {
		if (question) {
			let a =
				getSelectedAnswer &&
				getSelectedAnswer(question.formItemId, activeAnswers);
			setSelectedAnswer(a ?? "");
			setMaxStringLength(question.maxLength ?? 250);

			if (question.extensions?.length > 0) {
				question.extensions.forEach((ext) => {
					if (ext.name === "optional") {
						setOptionalText(ext.value);
					}
				});
			}
		}
	}, [question, activeAnswers]);

	const updateAnswer = (str: string) => {
		let answer = str.substring(0, maxStringLength);

		setSelectedAnswer(answer);
	};

	const getLabel = (label: string, optText: string) => {
		return label ? label + (label.includes(optText) ? "" : " " + optText)
		: optText
	}

	return (
		<Fragment>
			<TextField
				id={`text_field_${question.formItemId}`}
				fullWidth
				size={question.component === "TextInputComponent" ? "medium" : "small"}
				multiline={true}
				rows={3}
				value={selectedAnswer}
				className="text-input"
				label={getLabel(question.placeholder, optionalText)}
				onBlur={(e) => {
					if (e.target.value !== question.answer)
						handleResponse &&
							handleResponse(
								question.formItemId,
								question.formItemSequenceNumber,
								e.target.value,
								false
							);
				}}
				sx={{ 
					marginTop: 0, marginBottom: 0,
					'& label': {
						transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
						left: 'inherit !important',
						right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
					},
					'& legend': {
						textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
					}
				}}
				onChange={(e) => updateAnswer(e.target.value)}
				error={selectedAnswer.length > maxStringLength}
			/>
			<Grid container>
				<Grid item xs={12} style={{ textAlign: "right" }}>
					<Typography variant="caption">
						{selectedAnswer.length ?? 0}/{maxStringLength}
					</Typography>
				</Grid>
			</Grid>
		</Fragment>
	);
};
