import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
	setActiveAnswers,
	setActiveSurvey,
	setCurrentSection,
	setInstrumentId,
	setQuestionList,
} from "../../store/User/UserSlice";
import {
	setCurrentNavigationMode,
	setMainTabGroup,
	setRaterPageTitle,
	setTabs,
} from "../../store/Navigation/NavSlice";
import {
	NavigationModes,
	SurveyResponse,
	SurveySection,
} from "../../@types/surveys.d";

interface ResetProps {
	completed?: boolean
}

export const ResetSurveyStatus: React.FC<ResetProps> = ({ completed }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		// Reset everything related to a specific assessment. Just ensure we cleanup the state.
		if (!completed) {
			//if the callee is not the 'survey completed' page,
			//this can also be cleared
			dispatch(setActiveSurvey({} as SurveyResponse));
		}
		dispatch(setRaterPageTitle(""));
		dispatch(setCurrentNavigationMode(NavigationModes.BySection));
		dispatch(setTabs([]));
		dispatch(setMainTabGroup([]));

		dispatch(setCurrentSection({} as SurveySection));
		dispatch(setActiveAnswers(JSON.stringify([])));
		dispatch(setInstrumentId(0));
		dispatch(setQuestionList([]));
	}, []);

	return <Fragment></Fragment>;
};
