import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Modal, Stack, Toolbar, Typography } from "@mui/material";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import components from "../../theme/components";
import { FormattedPhrase } from "../../context/FormattedPhrase";
import { VideoPlayerWithAnalytics } from "../../utils/VideoPlayerWithAnalytics";
import { Link } from "react-router-dom";
import staticThumb from "../../assets/images/cclLogoChrome.png";

type VideoModalProps = {
	isOpen: boolean;
	onClose: () => void;
	videoList: any;
	thumbnailList?: any;

	name?: string;
	assessmentName?: string,
	playedFrom?: string
};

export const VideoModal: React.FC<VideoModalProps> = ({ isOpen, onClose, videoList, thumbnailList, name, assessmentName, playedFrom }) => {
	const [currentVideo, setCurrentVideo] = useState(videoList[0]);
	const [currentThumbnail, setCurrentThumbnail] = useState("");

	useEffect(() => {
		if (thumbnailList && thumbnailList.length > 0) {
			setCurrentThumbnail(thumbnailList[0]);
		} else {
			setCurrentThumbnail(staticThumb);
		}
		setCurrentVideo(videoList[0]);
	},[videoList,thumbnailList])

	return (
		<Modal open={isOpen} onClose={onClose}>
			<Box sx={components.MuiVideoModal} paddingLeft={0}>
				<VideoPlayerWithAnalytics 
					source={currentVideo} 
					poster={currentThumbnail} 
					type_title={name ?? ""}

					assessment={assessmentName ?? ""}
					played_from={playedFrom ?? ""}
				/>
				{ videoList.length > 1 &&
					<Grid container justifyContent={"space-evenly"} p={5}>
						{videoList.map((v, index) => { return (
							<Link to="" onClick={() => {setCurrentVideo(v); setCurrentThumbnail(thumbnailList[index] ?? staticThumb)}} style={{textDecoration: "none"}}>
								<Grid item>
									<Box width={200} height={113} sx={{ ":hover": {backgroundColor: "darkgray"}, ":hover .action": {opacity: 1}}}>
										<Stack sx={{alignItems: "center", justifyContent: "center", height: "100%"}}>
											<PlayCircleIcon sx={{color: "white"}} fontSize={"large"}/>
											<Typography color="white">Click to Play</Typography>
											<img
												className="thumb"
												src={thumbnailList[index] ?? staticThumb}
												style={{ width: 120, opacity: 1 }}
											/>
										</Stack>
										
									</Box>		
									
								</Grid>
							</Link>
						)})}
					</Grid>
				}
				<Toolbar sx={{ justifyContent: "flex-end" }}>
					<Button onClick={onClose}><FormattedPhrase id="dialog.ok"/></Button>
				</Toolbar>
			</Box>
		</Modal>
	);
};
