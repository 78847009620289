import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CssBaseline } from "@mui/material";

import { RootState } from "../../store";
import { setSelectedLanguage } from "../../store/User/UserSlice";
import { Language } from "../../@types/language.d";

import Header from "./Header";
import UserPanel from "./UserPanel";
import Footer from "./Footer";
import SessionTimeOut from "../surveys/SessionTimeOut";

import cultures from "../../assets/i18n/cultures.json";

import "./Layout.scss";

const drawerWidth = 350;

export function Layout(props: any) {
	const dispatch = useDispatch();
	const { profileResponse, selectedLanguage, selectedSurveyLanguage } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		if (profileResponse?.applicationLanguages) {
			let surveyLanguage = profileResponse.applicationLanguages.find(
				(l) => l.languageId === profileResponse.preferredLanguageId
			);
			dispatch(setSelectedLanguage(surveyLanguage as Language));
		}
	}, [profileResponse]);

	useEffect(() => {
		var el = document.getElementById("main_body");
		if (el) {
			cultures.forEach((value: string, index: number) => {
				if (el?.classList.contains(value)) el.classList.remove(value);
			});

			el.classList.add(selectedLanguage?.cultureName);
		}
	}, [selectedLanguage]);

	return (
		<div className={selectedSurveyLanguage.direction === "ltr" ? selectedLanguage?.direction ?? "ltr" : "rtl"}>
			<CssBaseline />
			<UserPanel />
			<Header />
			<Box id="survey_main">{props.children}</Box>
			<Footer width={drawerWidth} />
			<SessionTimeOut />
		</div>
	);
}
