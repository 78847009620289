import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const VideoJS = (props) => {
    const videoRef = React.useRef<any>(null);
    const playerRef = React.useRef<any>(null);
    const options = {
        controls: true,
        responsive: true,
        fluid: true,
        autoplay: false,
        controlBar: {
            volumePanel: {
                inline: true
            },
            // skipButtons: {
            //     forward: 5,
            //     backward: 5,
            // }
        },
        sources: [{
            src: props.source,
            type: 'video/mp4'
        }],
        poster: props.poster
    }
    const onReady = props.onReady;

    React.useEffect(() => {
        if (!playerRef.current) {
        // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
        const videoElement = document.createElement("video-js");

        videoElement.classList.add('vjs-big-play-centered');
        videoRef.current.appendChild(videoElement);

        const player = playerRef.current = videojs(videoElement, options, () => {
            // videojs.log('player is ready');
            onReady && onReady(player);
        });

        // You could update an existing player in the `else` block here
        // on prop change, for example:
        } else {
        const player = playerRef.current;

        player.autoplay(options.autoplay);
        player.src(options.sources);
        }
    }, [options, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player>
            <div ref={videoRef} />
        </div>
    );
}

export default VideoJS;