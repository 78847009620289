import React from "react";
import { Stack, Backdrop, Typography, CircularProgress } from "@mui/material";

export interface PageLoaderProps {
	msg: string;
}

export const PageLoader: React.FC<PageLoaderProps> = (props) => {
	return (
		<Backdrop
			sx={{ color: "#000", zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={true}
		>
			<Stack spacing={2} justifyContent="center" alignItems="center">
				<CircularProgress color="inherit" />
				<Typography align="center" variant="h6" component="div">
					{props.msg}
				</Typography>
			</Stack>
		</Backdrop>
	);
};

export default PageLoader;
