import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom-v5-compat";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Grid,
	Modal,
	Stack,
	Toolbar,
	Typography,
	useTheme,
} from "@mui/material";

import {
	EmailRequest,
	InstrumentRaterResponse,
	Rater,
	RaterStatus,
	RaterType,
} from "../../@types/raters.d";

import { Layout } from "../../components/chrome/Layout";
import { getAllRaters, remindRaters } from "../../services/apiService";
import { Link, useHistory } from "react-router-dom";
import { ConfirmationModal } from "../../components/shared/confirmationModal";
import { RootState } from "../../store";
import {
	setInstrumentId,
	setInstrumentRaterResponse,
	setParticipantId,
	setRaterReminderLastSentAt,
} from "../../store/User/UserSlice";
import Loading from "../../components/chrome/Loading";
import { setRaterPageTitle } from "../../store/Navigation/NavSlice";
import raterImage from "../../assets/images/rater_page.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import components from "../../theme/components";
import { RaterTypeCard } from "./RaterTypeCard";
import { FormattedPhrase } from "../../context/FormattedPhrase";
import { useTranslationContext } from "../../context/TranslationContext";
import moment from "moment";
import InfoIcon from '@mui/icons-material/Info';
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import { useEnv } from "../../context/env.context";
import { getAnalytics, logEvent } from "firebase/analytics";

// This is a shared method that is used to Remind specific rater types (in ReaterTypeCard) or all raters (in AssessmentRaters)
const sendRaterReminders = async (
	participantId: number,
	instrumentRaterResponse: InstrumentRaterResponse,
	raterType?: RaterType
) => {
	let emailRequests: Array<EmailRequest> = [];
	if (instrumentRaterResponse) {
		if (raterType) {
			// Find raters of this type
			instrumentRaterResponse.raterStatuses.forEach((status: RaterStatus) => {
				status.raters.forEach((rater: Rater) => {
					if (rater.raterTypeId === raterType.id) {
						emailRequests.push({
							participantId: participantId,
							raterId: rater.raterId,
						});
					}
				});
			});
		} else {
			// Find and add all raters regardless of type
			instrumentRaterResponse.raterStatuses.forEach((status: RaterStatus) => {
				status.raters.forEach((rater: Rater) => {
					emailRequests.push({
						participantId: participantId,
						raterId: rater.raterId,
					});
				});
			});
		}
	}

	return await remindRaters(emailRequests);
};

export const AssessmentRaters: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { domain } = useEnv();
	const { getFormattedPhrase } = useTranslationContext();
	const translatedLabels = {
		add: getFormattedPhrase("raterManagement.addRater.add"),
		rater: getFormattedPhrase("raterManagement.addRater.rater"),
		raters: getFormattedPhrase("raterManagement.addRater.raters"),
	};

	const faqText = getFormattedPhrase("dialog.faq.text");
	const faqContent = JSON.parse(faqText);
	const [FAQModalOpen, setFAQModalOpen] = useState(false);
	const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
	const [activeFaq, setActiveFaq] = useState(-1);

	const theme = useTheme();

	const navigate = useNavigate();

	const [search] = useSearchParams();

	const { instrumentRaterResponse, instrumentId, loading, participantId, raterReminderLastSentAt, dateFormat, userIdForAnalytics } =
		useSelector((state: RootState) => state.user);

	const [showReminderStatus, setShowReminderStatus] = useState<string | null>(
		null
	);
	const [showRemindDialog, setShowRemindDialog] = useState<boolean>(false);
	// const [reminderTimeout, setReminderTimeout] = useState(true);

	const showRemindRatersButton = () => {
		let showButton = false;

		if (instrumentRaterResponse?.raterStatuses) {
			instrumentRaterResponse.raterStatuses.forEach((status: RaterStatus) => {
				if (status.raters && status.raters.length > 0) {
					showButton = true;
				}
			});
		}
		return showButton;
	}

	const loadRaters = async () => {
		let response = await getAllRaters(
			participantId.toString(),
			instrumentId.toString(),
			"1"
		);

		dispatch(setInstrumentRaterResponse(response));
		dispatch(
			setRaterPageTitle(
				getFormattedPhrase("raterManagement.header.title").replace(
					"{{name}}",
					response?.instrument?.name
				)
			)
		);
	};

	const asyncLoadingTasks = async () => {
		dispatch(setInstrumentRaterResponse({} as InstrumentRaterResponse));

		// On init page load, go get the user's rater statuses
		let id = search.get("id");
		let pid = search.get("pid");

		if (id && pid) {
			dispatch(setInstrumentId(parseInt(id)));
			dispatch(setParticipantId(parseInt(pid)));
		} else {
			navigate("/");
		}
	};

	//withdrawing timeouts temporarily - 78254
	// useEffect(() => {
	// 	if (raterReminderLastSentAt) {
	// 		let timeoutCrossed = ((moment(Date.now()).diff(moment(raterReminderLastSentAt), "minutes")) as number > 10)
	// 		if (!timeoutCrossed !== reminderTimeout) {
	// 			setReminderTimeout(!timeoutCrossed);
	// 		}
	// 	} else {
	// 		setReminderTimeout(false);
	// 	}
	// },[reminderTimeout, raterReminderLastSentAt])

	useEffect(() => {
		asyncLoadingTasks();
		window.scrollTo(0,0);
	}, []);

	useEffect(() => {
		if (instrumentId && participantId) {
			loadRaters();
		}
	}, [instrumentId, participantId]);

	useEffect(() => {
		let redirectUrl = localStorage.getItem("returnToAp1");
		let redirectUser = localStorage.getItem("redirectUser");

		if (redirectUser === "true" && redirectUrl) {
			// redirect user only if they have come from AP1
			localStorage.setItem("redirectUser", "");
			localStorage.setItem("returnToAp1", "");
			window.location.href = redirectUrl;
		}
	},[]);

	useEffect(() => {
		const handleRefresh = (event: Event) => {
			localStorage.setItem("redirectUser", "");
			let returnUrl = localStorage.getItem("returnToAp1");
			if (returnUrl !== null) {
				history.replace(returnUrl);
				localStorage.setItem("redirectUser", "true");
				return;
			}
		};

		window.addEventListener('beforeunload', handleRefresh);

		return () => {
			window.removeEventListener('beforeunload', handleRefresh);
		};
	}, []);

	return (
		<Layout>
			{loading && <Loading />}
			{instrumentRaterResponse?.instrument?.name && (
				<Grid container p={0} m={0}>
					<Grid item xs={0} md={4}>
						<Card
							sx={{
								m: 3,
								boxShadow:
									"0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
							}}
						>
							<CardContent>
								<Grid container spacing={0} rowSpacing={0}>
									<img src={raterImage} alt="Rater" style={{ width: "100%" }} />
									<Grid item xs={12} className="surveyCard" py={5}>
										<Typography variant="h3" fontWeight={900}>
											{translatedLabels.raters}
										</Typography>
									</Grid>
									<Grid item xs={12} className="surveyCard">
										<Modal
											open={FAQModalOpen}
											onClose={() => setFAQModalOpen(false)}
										>
											<Box sx={components.MuiModal} height={"75%"}>
												<Stack direction="row" alignItems={"center"} mb={5}>
													<Typography variant="h4" m={1} sx={{ flex: 1 }}>
														<FormattedPhrase id="raterManagement.header.instructions.link" />
													</Typography>
													<CloseIcon
														sx={{ mr: "1%", cursor: "pointer" }}
														onClick={() => setFAQModalOpen(false)}
													/>
												</Stack>
												<Box overflow={"scroll"} height={"85%"}>
													<Typography variant="subtitle1">
														{faqContent.length > 0 ? (
															faqContent.map((faq, index) => {
																return (
																	<Accordion
																		key={index}
																		expanded={activeFaq === index}
																		onChange={() =>
																			setActiveFaq(
																				activeFaq === index ? -1 : index
																			)
																		}
																	>
																		<AccordionSummary
																			expandIcon={<ExpandMoreIcon />}
																		>
																			<div
																				dangerouslySetInnerHTML={{
																					__html: faq.question,
																				}}
																			></div>
																		</AccordionSummary>
																		<AccordionDetails>
																			<div
																				dangerouslySetInnerHTML={{
																					__html: faq.answer,
																				}}
																			></div>
																		</AccordionDetails>
																	</Accordion>
																);
															})
														) : (
															<></>
														)}
													</Typography>
												</Box>
											</Box>
										</Modal>
										<Modal
											open={scheduleModalOpen}
											onClose={() => setScheduleModalOpen(false)}
										>
											<Box sx={components.MuiModal} height={"75%"}>
												<Typography variant="h3" my={2}>
													<FormattedPhrase id="raterManagement.header.reminderSchedule.title" />
												</Typography>
												<Box overflow={"scroll"} height={"75%"}>
													{instrumentRaterResponse?.program?.reminderSchedule
														?.length === 0 ? (
														<Fragment>
															<Typography variant="subtitle1">
																<FormattedPhrase id="raterManagement.header.reminderSchedule.noSchedule" />
															</Typography>
														</Fragment>
													) : (
														<Fragment>
															{instrumentRaterResponse?.program?.reminderSchedule.map(
																(reminderSchedule, index) => {
																	return (
																		<Typography variant="subtitle1">
																			{moment(
																				reminderSchedule.reminderDate
																			).format(dateFormat)}
																		</Typography>
																	);
																}
															)}
														</Fragment>
													)}
												</Box>
												<Toolbar sx={{ justifyContent: "flex-end" }}>
													<Button onClick={() => setScheduleModalOpen(false)}>
														<FormattedPhrase id="dialog.ok"/>
													</Button>
												</Toolbar>
											</Box>
										</Modal>
										<ConfirmationModal
											isOpen={showReminderStatus !== null}
											onYes={() => {
												setShowReminderStatus(null);
											}}
											title={getFormattedPhrase(
												"raterManagement.remindRaters.title"
											)}
											promptText={getFormattedPhrase(
												"raterManagement.remindRatersSuccess.confirmation"
											)}
											yesText={getFormattedPhrase("dialog.ok")}
										/>
										<Typography variant="subtitle1">
											<Link to="#" onClick={() => setFAQModalOpen(true)} style={{color: "#2F5F95"}}>
												<FormattedPhrase id="raterManagement.header.instructions.link" />
											</Link>
										</Typography>
										<Typography variant="subtitle1">
											<Link to="#" onClick={() => setScheduleModalOpen(true)} style={{color: "#2F5F95"}}>
												<FormattedPhrase id="raterManagement.header.reminderSchedule.title" />
											</Link>
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Grid container>
											<Grid item xs={12} sx={{ textAlign: "end" }}>
												{ showRemindRatersButton() && ( 
												<Button
													variant="outlined"
													className="button-header"
													sx={{
														backgroundColor:
															theme.palette.secondary.contrastText,
														borderRadius: "100px",
														mr: 2,
													}}
													onClick={() => {
																setShowRemindDialog(true);
																	logEvent(getAnalytics(), "[BUTTON] Remind All Raters",
																		{"User": (userIdForAnalytics)}
																	);
															}}
													>
													{/* {reminderTimeout ? <CircularProgress size={15} sx={{mr: 2}}/> : <NotificationsIcon sx={{ mr: 2 }} />} */}
													<NotificationsIcon sx={{ mr: 2 }} />
													<FormattedPhrase id="raterManagement.buttons.remindAll" />
												</Button>
												)}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} md={8}>
						<Card
							sx={{
								m: 3,
								borderColor: theme.palette.primary.main,
								borderWidth: 1,
								borderStyle: "solid",
							}}
						>
							<Stack sx={{ flexDirection: "row", p: 3, pt: 2 }}>
								<InfoIcon
									sx={{ mr: 2, color: theme.palette.primary.main }}
								/>
								<Typography>
									<FormattedPhrase id="raterManagement.header.instructions.notice"/>
								</Typography>
							</Stack>
						</Card>
						<Grid container spacing={0} rowSpacing={0}>
							{instrumentRaterResponse?.raterStatuses?.map(
								(raterStatus: RaterStatus, index: number) => (
									<Grid item key={index} xs={12}>
										<RaterTypeCard
											raterStatus={raterStatus}
											onReload={async () => {
												if (instrumentId && participantId) {
													loadRaters();
												}
											}}
										/>
									</Grid>
								)
							)}
						</Grid>
						<ConfirmationModal
							isOpen={showRemindDialog}
							title={getFormattedPhrase("raterManagement.remindRaters.title")}
							promptText={getFormattedPhrase(
								"raterManagement.remindRaters.confirmation"
							)}
							yesText={getFormattedPhrase("dialog.yes")}
							defaultButton={getFormattedPhrase("dialog.yes")}
							noText={getFormattedPhrase("dialog.no")}
							onYes={async () => {
								let response = await sendRaterReminders(
									participantId,
									instrumentRaterResponse
								);
								if (response.result === "Success") {
									let msg = getFormattedPhrase(
										"raterManagement.remindRatersSuccess.confirmation"
									);
									setShowReminderStatus(msg);
									dispatch(setRaterReminderLastSentAt(moment(Date.now()).toString()));
								}
								setShowRemindDialog(false);
							}}
							onNo={() => setShowRemindDialog(false)}
						/>
					</Grid>
				</Grid>
			)}
		</Layout>
	);
};
