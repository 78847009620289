import merge from "deepmerge";
import { green, grey, indigo, red } from "@mui/material/colors";
import { THEMES } from "../constants";

const customBlue = {
	50: "#e9f0fb",
	100: "#c8daf4",
	200: "#a3c1ed",
	300: "#7ea8e5",
	400: "#6395e0",
	500: "#4782da",
	600: "#407ad6",
	700: "#376fd0",
	800: "#2f65cb",
	900: "#2052c2 ",
};

const defaultVariant = {
	name: THEMES.DEFAULT,
	palette: {
		mode: "light",
		primary: {
			main: "#2F5F95", // main color
			light: "#F0F1F3",
			dark: "#2F5F95", // Hover color according to the documentation
			contrastText: "#F1F1F2",
		},
		secondary: {
			main: "#307E92",
			contrastText: "#FFFFFF",
			light: "#CDE9E6",
			dark: "#EBF3F6",
		},
		info: {
			main: "#335F83",
			light: "#FFFFFF",
			dark: "#76777B",
			contrastText: "#000000",
		},
		neutral: {
			submit: "#CCCCCD",
			buttonText: "#8A898B",
		},
		text: {
			primary: "#3B3B3B",
			secondary: "#8A8C8F",
			disabled: "#76777B",
		},
		action: {
			disabledBackground: "#B07DB4",
			disabled: "#FFFFFF",
			active: "#B07DB4",
		},
		success: {
			main: "#006E63",
			light: "#BABBBD",
			dark: "#A267A7"
		},
		error: {
			main: "#BA1A1A",
		},
		warning: {
			main: "#EE7624",
			light: "#1C1B1F",
			dark: "#BABBBD",
			contrastText: "#F2E9F2"
		},
	},
	header: {
		color: grey[500],
		background: "#FFF",
		search: {
			color: grey[800],
		},
		indicator: {
			background: customBlue[600],
		},
	},
	footer: {
		color: grey[500],
		background: "#FFF",
	},
	sidebar: {
		color: grey[200],
		background: "#233044",
		header: {
			color: grey[200],
			background: "#233044",
			brand: {
				color: "customBlue[500]",
			},
		},
		footer: {
			color: grey[200],
			background: "#1E2A38",
			online: {
				background: green[500],
			},
		},
		badge: {
			color: "#FFF",
			background: customBlue[500],
		},
	},
};

const darkVariant = merge(defaultVariant, {
	name: THEMES.DARK,
	palette: {
		mode: "dark",
		primary: {
			main: "#00C4B4",
			light: "#62fff2",
			dark: "#00b0a2",
			contrastText: "#000000",
		},
		secondary: {
			main: customBlue[500],
			contrastText: "#FFFFFF",
			light: "#FFFFFF",
			dark: "#4C4E56",
		},
		info: {
			main: "#55585F",
			light: "#FFFFFF",
			dark: "#00b0a2",
			contrastText: "#FFFFFF ",
		},
		background: {
			default: "#404040",
			paper: "#121212",
			settingsTile: "#353841",
		},
		text: {
			primary: "rgba(255, 255, 255, 0.95)",
			secondary: "#1F222C",
			disabled: "#4C4E56",
		},
		action: {
			disabledBackground: "#00e3d1",
			disabled: "#BABBBD",
			active: "#FFFFFF",
		},
		success: {
			main: "#00C4B4",
			light: "#BABBBD",
		},
		error: {
			main: "#D44F6E",
		},
	},
	header: {
		color: grey[300],
		background: "#1B2635",
		search: {
			color: grey[200],
		},
	},
	footer: {
		color: grey[300],
		background: "#233044",
	},
	sidebar: {
		color: grey[200],
		background: "#233044",
		header: {
			color: grey[200],
			background: "#233044",
			brand: {
				color: customBlue[500],
			},
		},
		footer: {
			color: grey[200],
			background: "#1E2A38",
			online: {
				background: green[500],
			},
		},
		badge: {
			color: "#FFF",
			background: customBlue[500],
		},
	},
});

const lightVariant = merge(defaultVariant, {
	name: THEMES.LIGHT,
	palette: {
		mode: "light",
	},
	header: {
		color: grey[200],
		background: customBlue[800],
		search: {
			color: grey[100],
		},
		indicator: {
			background: red[700],
		},
	},
	sidebar: {
		color: grey[900],
		background: "#FFF",
		header: {
			color: "#FFF",
			background: customBlue[800],
			brand: {
				color: "#FFFFFF",
			},
		},
		footer: {
			color: grey[800],
			background: "#F7F7F7",
			online: {
				background: green[500],
			},
		},
	},
});

export type VariantType = {
	name: string;
	palette: {
		primary: MainContrastTextType;
		secondary: MainContrastTextType;
	};
	header: ColorBgType & {
		search: {
			color: string;
		};
		indicator: {
			background: string;
		};
	};
	footer: ColorBgType;
	sidebar: ColorBgType & {
		header: ColorBgType & {
			brand: {
				color: string;
			};
		};
		footer: ColorBgType & {
			online: {
				background: string;
			};
		};
		badge: ColorBgType;
	};
};

type MainContrastTextType = {
	main: string;
	contrastText: string;
};
type ColorBgType = {
	color: string;
	background: string;
};

const variants: Array<VariantType> = [
	defaultVariant,
	darkVariant,
	lightVariant,
];

export default variants;
