import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Typography,
	TextField,
	Box,
	Stack,
} from "@mui/material";

import {
	fetchProfile,
	fetchVideos,
	setDateFormat,
	setLoading,
	setSelectedLanguage,
	setSelectedSurveyLanguage,
} from "../store/User/UserSlice";

import profilePic from "../assets/images/cclLogoChrome.png";

import { Loading } from "../components";

import { RootState } from "../store";

import { Layout } from "../components/chrome/Layout";
import { useTheme } from "@mui/material/styles";
import { Language } from "../@types/language.d";
import { Profile } from "../@types/surveys.d";
import { saveUserProfile } from "../services/apiService";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslationContext } from "../context/TranslationContext";
import { ConfirmationModal } from "../components/shared/confirmationModal";
import { ResetSurveyStatus } from "./home/ResetSurveyStatus";

import "./ProfilePage.scss";
import { FormattedPhrase } from "../context/FormattedPhrase";
import { useHistory } from "react-router-dom";

const ProfilePage: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const theme = useTheme();
	const { user } = useAuth0();

	const { loading, profileResponse, selectedLanguage } = useSelector(
		(state: RootState) => state.user
	);

	const { getFormattedPhrase } = useTranslationContext();
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");

	const labels = {
		firstName: getFormattedPhrase("myProfile.placeholder.firstName"),
		middleName: getFormattedPhrase("myProfile.placeholder.middleInitials"),
		lastName: getFormattedPhrase("myProfile.placeholder.lastName"),
		preferredName: getFormattedPhrase("myProfile.placeholder.preferredName"),
		email: getFormattedPhrase("myProfile.placeholder.email"),
		bizPhone: getFormattedPhrase("myProfile.placeholder.businessPhone"),
		mobPhone: getFormattedPhrase("myProfile.placeholder.mobilePhone"),
		jobTitle: getFormattedPhrase("myProfile.placeholder.jobTitle"),
		prefAppLang: getFormattedPhrase(
			"myProfile.placeholder.preferredApplicationLanguage"
		),
		dateFormat: getFormattedPhrase("myProfile.placeholder.dateFormat"),
		saveButton: getFormattedPhrase("myProfile.button.saveChanges"),
	};

	useEffect(() => {}, [profileResponse]);

	useEffect(() => {
		let redirectUrl = localStorage.getItem("returnToAp1");
		let redirectUser = localStorage.getItem("redirectUser");

		if (redirectUser === "true" && redirectUrl) {
			// redirect user only if they have come from AP1
			localStorage.setItem("redirectUser", "");
			localStorage.setItem("returnToAp1", "");
			window.location.href = redirectUrl;
		}
	},[]);

	useEffect(() => {
		const handleRefresh = (event: Event) => {
			localStorage.setItem("redirectUser", "");
			let returnUrl = localStorage.getItem("returnToAp1");
			if (returnUrl !== null) {
				history.replace(returnUrl);
				localStorage.setItem("redirectUser", "true");
				return;
			}
		};

		window.addEventListener('beforeunload', handleRefresh);

		return () => {
			window.removeEventListener('beforeunload', handleRefresh);
		};
	}, []);


	const EditProfile = () => {
		const [firstName, setFirstName] = useState<string>(
			profileResponse?.individual?.firstName!
		);
		const [lastName, setLastName] = useState<string>(
			profileResponse?.individual?.lastName
		);
		const [middleName, setMiddleName] = useState<string | undefined>(
			profileResponse?.individual?.middleInitials
		);
		const [preferredName, setPreferredName] = useState<string | undefined>(
			profileResponse?.individual?.preferredName
		);
		const [businessPhone, setBusinessPhone] = useState<string | undefined>(
			profileResponse?.communication?.businessPhone
		);
		const [mobilePhone, setMobilePhone] = useState<string | undefined>(
			profileResponse?.communication?.mobilePhone
		);
		const [email, setEmail] = useState<string>(
			profileResponse?.communication?.email
		);
		const [jobTitle, setJobTitle] = useState<string | undefined>(
			profileResponse?.individual?.jobTitle
		);
		const [preferredLanguage, setPreferredLanguage] = useState<number>(
			profileResponse?.individual?.preferredApplicationLanguageId
		);
		const [preferredDateFormat, setPreferredDateFormat] = useState<string>(
			profileResponse?.individual?.preferredDateFormat
		);

		const saveProfile = () => {
			if (firstName !== "" && lastName !== "" && preferredLanguage > 0) {
				if (profileResponse) {
					if (
						profileResponse?.individual.firstName == firstName &&
						profileResponse?.individual.middleInitials == middleName &&
						profileResponse?.individual.lastName == lastName &&
						profileResponse?.individual.preferredName == preferredName &&
						profileResponse?.individual.jobTitle == jobTitle &&
						profileResponse?.individual.preferredApplicationLanguageId == preferredLanguage &&
						profileResponse?.individual.preferredDateFormat == preferredDateFormat &&
						profileResponse?.communication.mobilePhone == mobilePhone &&
						profileResponse?.communication.businessPhone == businessPhone
					) {
						setAlertMsg("\n\n"+getFormattedPhrase("myProfile.validationMessage.noChanges"));
						setShowAlert(true);
					} else {
						dispatch(setLoading(true));
						const newProfile: Profile = {
							individual: {
								individualId: profileResponse?.individual.individualId,
								prefix: profileResponse?.individual.prefix,
								firstName: firstName,
								middleInitials: middleName,
								lastName: lastName,
								preferredName: preferredName,
								jobTitle: jobTitle,
								preferredApplicationLanguageId: preferredLanguage,
								preferredApplicationLanguageCultureName:
									profileResponse?.individual
										?.preferredApplicationLanguageCultureName,
										preferredDateFormat: preferredDateFormat
							},
							communication: {
								businessPhone: businessPhone,
								mobilePhone: mobilePhone,
								email: email,
							},
							applicationLanguages: profileResponse.applicationLanguages,
							preferredLanguageId: profileResponse.preferredLanguageId,
							preferredLanguageCultureName:
								profileResponse.preferredLanguageCultureName,
							isParticipant: profileResponse.isParticipant,
							isRater: profileResponse.isRater,
							landingPage: profileResponse.landingPage,
							aboutMe: profileResponse.aboutMe,
						};

						saveUserProfile(newProfile)
							.then((res) => {
								dispatch(setLoading(true));
								if (res.profile) {
									if (user) {
										let imKey =
											user[
												"http://schemas.ccl.org/accounts/claims/ccl-identity/id"
											];

										let data = {
											user: imKey,
											languageId: preferredLanguage,
										};
										dispatch(fetchProfile(data));
										

										let surveyLanguage =
											profileResponse.applicationLanguages.find(
												(l) => l.languageId === preferredLanguage
											);
										dispatch(setSelectedLanguage(surveyLanguage as Language));
										dispatch(setSelectedSurveyLanguage(surveyLanguage as Language));
										dispatch(setDateFormat(preferredDateFormat === "2" ? "DD/MM/yyyy" : "MM/DD/yyyy"));
										dispatch(fetchVideos(surveyLanguage?.cultureName ?? "en-us"));
									}
									setTimeout(() => {
										dispatch(setLoading(false));
									}, 500);
								}
							})
							.catch((e) => {
								setTimeout(() => {
									console.log("error while saving");
									console.log(e);
									dispatch(setLoading(false));
								}, 500);
							});
					}
				}
			}
		};

		const getLanguagesList = () => {
			//temporarily disabling Arabic - ref PBI 76670
			//re-enabling since the necessary change has been done on the DB
			return profileResponse?.applicationLanguages;//?.slice().filter((l) => l.cultureName !== "ar-SA");
		}

		return (
			<Grid container justifyContent={"center"}>
				<Grid
					item
					md={9}
					lg={5}
					xl={5}
					sx={{ backgroundColor: theme.palette.secondary.contrastText, mt: 5 }}
					id="info"
				>
					<Box id="image-style" sx={{ alignItems: "flex-start", display: "flex", overflow: "hidden", flex: 1 }}>
						<img
							src={profilePic}
							alt="Profile Screen Image"
							style={{ width: "30%", height:"100px", float: "left", marginTop: -25, paddingLeft: 15, transform: 'scale(2.5)', overflow: "hidden", backgroundColor: "#FFFFFF" }}
						/>
						<Typography
							variant="body1"
							sx={{ fontSize: "1.625rem", my: 5, mx: 10, width: "80%", whiteSpace: 'pre-wrap', wordBreak: 'break-word'  }}
							noWrap={false}
							textAlign={"right"}
						>
							<FormattedPhrase id="myProfile.profile" />
						</Typography>
					</Box>
					<Stack id="labels">
						<TextField
							id="firstName"
							required
							size={"small"}
							label={labels.firstName}
							value={firstName}
							className="text-input"
							onChange={(event: any) => setFirstName(event?.target?.value)}
							error={firstName === ""}
							sx={{ 
								'& label': {
									transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
									left: 'inherit !important',
									right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
								},
								'& legend': {
									textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
								},
								'& .MuiInputLabel-root': {
									color: 'black',
								},
							}}
						/>
						<TextField
							id="middleName"
							size={"small"}
							label={labels.middleName}
							value={middleName}
							className="text-input"
							onChange={(event: any) => setMiddleName(event?.target?.value)}
							sx={{ 
								'& label': {
									transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
									left: 'inherit !important',
									right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
								},
								'& legend': {
									textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
								},
								'& .MuiInputLabel-root': {
									color: 'black',
								},
							}}
						/>
						<TextField
							id="lastName"
							required
							size={"small"}
							label={labels.lastName}
							value={lastName}
							className="text-input"
							onChange={(event: any) => setLastName(event?.target?.value)}
							error={lastName === ""}
							sx={{ 
								'& label': {
									transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
									left: 'inherit !important',
									right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
								},
								'& legend': {
									textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
								},
								'& .MuiInputLabel-root': {
									color: 'black',
								},
							}}
						/>
						<TextField
							id="preferredName"
							size={"small"}
							label={labels.preferredName}
							value={preferredName}
							className="text-input"
							onChange={(event: any) => setPreferredName(event?.target?.value)}
							sx={{ 
								'& label': {
									transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
									left: 'inherit !important',
									right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
								},
								'& legend': {
									textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
								},
								'& .MuiInputLabel-root': {
									color: 'black',
								},
							}}
						/>
						<TextField
							id="email"
							size={"small"}
							label={labels.email}
							value={email}
							InputProps={{
								readOnly: true,
							}}
							className="text-input"
							onChange={(event: any) => setEmail(event?.target?.value)}
							sx={{ 
								'& label': {
									transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
									left: 'inherit !important',
									right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
								},
								'& legend': {
									textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
								},
								'& .MuiInputLabel-root': {
									color: 'black',
								},
							}}
						/>
						<TextField
							id="businessPhone"
							size={"small"}
							label={labels.bizPhone}
							value={businessPhone}
							className="text-input"
							onChange={(event: any) => setBusinessPhone(event?.target?.value)}
							sx={{ 
								'& label': {
									transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
									left: 'inherit !important',
									right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
								},
								'& legend': {
									textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
								},
								'& .MuiInputLabel-root': {
									color: 'black',
								},
							}}
						/>
						<TextField
							id="mobilePhone"
							size={"small"}
							label={labels.mobPhone}
							value={mobilePhone}
							className="text-input"
							onChange={(event: any) => setMobilePhone(event?.target?.value)}
							sx={{ 
								'& label': {
									transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
									left: 'inherit !important',
									right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
								},
								'& legend': {
									textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
								},
								'& .MuiInputLabel-root': {
									color: 'black',
								},
							}}
						/>
						<TextField
							id="jobTitle"
							size={"small"}
							label={labels.jobTitle}
							value={jobTitle}
							className="text-input"
							onChange={(event: any) => setJobTitle(event?.target?.value)}
							sx={{ 
								'& label': {
									transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
									left: 'inherit !important',
									right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
								},
								'& legend': {
									textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
								},
								'& .MuiInputLabel-root': {
									color: 'black',
								},
							}}
						/>
						<FormControl className="text-input" aria-label={labels.prefAppLang}
						sx={{ 
							margin: 4,
							'& label': {
								transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
								left: 'inherit !important',
								right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
							},
							'& legend': {
								textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
							},
							'& .MuiInputLabel-root': {
								color: 'black',
							},
						}}>
							<InputLabel required id="demo-simple-select-label">
								{labels.prefAppLang}
							</InputLabel>
							<Select
								labelId="preferredApplicationLanguageId"
								required
								id="preferredApplicationLanguage"
								value={preferredLanguage}
								size="small"
								label={labels.prefAppLang}
								error={!preferredLanguage || preferredLanguage === 0}
								onChange={(event: any) =>
									setPreferredLanguage(event?.target?.value)
								}
							>
								{getLanguagesList()?.map((x, index) => {
									return (
										<MenuItem key={index} value={x.languageId}>
											{x.name}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
						<FormControl className="text-input" aria-label={labels.dateFormat}
							sx={{ 
								margin: 4,
								'& label': {
									transformOrigin: selectedLanguage?.direction === "rtl" ? 'right !important' : 'left !important',
									left: 'inherit !important',
									right: selectedLanguage?.direction === "rtl" ? '1.75rem !important' : 'inherit !important',
								},
								'& legend': {
									textAlign: selectedLanguage?.direction == "rtl" ? 'right' : 'left',
								},
								'& .MuiInputLabel-root': {
									color: 'black',
								},
							}}>
								<InputLabel id="demo-simple-select-label">
									{labels.dateFormat}
								</InputLabel>
								<Select
									labelId="dateFormat"
									id="dateFormat"
									value={preferredDateFormat}
									size="small"
									label={labels.dateFormat}
									error={!preferredDateFormat}
									onChange={(event: any) => {
										setPreferredDateFormat(event?.target?.value)}
									}
								>
									<MenuItem value={"1"}>
										MM/DD/YYYY
									</MenuItem>
									<MenuItem value={"2"}>
										DD/MM/YYYY
									</MenuItem>
								</Select>
						</FormControl>
					</Stack>
					<Box className="save-button">
						<Button
							aria-label={labels.saveButton}
							disabled={!firstName || !lastName || !preferredLanguage}
							variant="contained"
							color="primary"
							sx={{ borderRadius: 5 }}
							onClick={() => saveProfile()}
						>
							<Typography>{labels.saveButton}</Typography>
						</Button>
					</Box>
				</Grid>
			</Grid>
		);
	};

	return (
		<Layout>
			{loading && <Loading />}
			<Grid container id="profile">
				<Grid item xs={4}></Grid>
				<EditProfile />
				<ConfirmationModal
					isOpen={showAlert}
					title={getFormattedPhrase("myProfile.dialog.title")}
					promptText={alertMsg}
					yesText={getFormattedPhrase("dialog.ok")}
					defaultButton={getFormattedPhrase("dialog.ok")}
					onYes={() => {
						setShowAlert(false);
					}}
				/>
				<Grid item xs={4} sx={{ margin: 5 }}></Grid>
			</Grid>
			<ResetSurveyStatus />
		</Layout>
	);
};

export default ProfilePage;
